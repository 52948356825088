import React, { useContext } from 'react';
import logo from "../../Assets/logo_softwarehouse.svg"
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../Context/LanguageContext';

export const Footer = () => {

	const { texto } = useContext(LanguageContext);

	return (<>
		<footer className="text-center text-lg-start bg-light text-muted">
			{/* Posible zona de reses sociales  */}
			{/* <section className="d-flex justify-content-center justify-content-lg-between py-2 px-4 border-bottom">
                <div className="me-5 d-none d-lg-block">
                    <span>Conéctate con nosotros en las redes sociales:</span>
                </div>
                <div>
                    <a className="btn text-white bg-success btn-rounded" href="https://wa.me/593984266250" target='blank' role="button">
                        <i className="bi bi-whatsapp font-14"></i>
                    </a>
                </div>
            </section> */}
			<section className="p-1">
				<div className="container-fluid text-center text-lg-start mt-5">
					<div className="row mt-2">
						<div className="col-md-6 col-lg-4 col-xl-3 mx-auto mb-4">
							<img src={logo} height="65" alt="Soft Warehouse" loading="lazy" />
							<p className='text-section font-16'>{texto.footer.frase}</p>
						</div>
						<div className="col-md-6 col-lg-2 col-xl-2 mx-auto mb-2 ">
							<h6 className="text-section text-uppercase fw-bold mb-2">
								{texto.nav.productos}
							</h6>
							<p className='text-section font-16 text-nav'>
								<Link to='/producto/2/FIT-BANK' className="text-reset ">Fit-Bank</Link>
							</p>
							<p className='text-section font-16 text-nav'>
								<Link to='/producto/1/FIT-COOP' className="text-reset">Fit-Coop</Link>
							</p>
							<p className='text-section font-16 text-nav'>
								<Link to='/producto/3/FIT-FENICIOS' className="text-reset">Fit-Fenicios</Link>
							</p>
						</div>
						<div className="col-md-6 col-lg-2 col-xl-2 mx-auto mb-2">
							<h6 className="text-section text-uppercase fw-bold mb-2">
								{texto.nav.titulo}
							</h6>
							<p className='text-section font-16 text-nav'>
								<Link to='/producto' className="text-reset">{texto.nav.productos}</Link>
							</p>
							{/* <p className='text-section font-16'>
                                <Link to='/modulo' className=" text-reset">Módulos</Link>
                            </p> */}
							<p className='text-section font-16 text-nav'>
								<Link to="/clientes" className="text-reset">{texto.nav.clientes}</Link>
							</p>
							{/* <p className='text-section font-16'>
                                <Link to="/servicio" className="text-reset">Servicios</Link>
                            </p> */}
							<p className='text-section font-16 text-nav'>
								<Link to='/nosotros' className="text-reset">{texto.nav.nosotros}</Link>
							</p>
						</div>
						<div className="col-md-6 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-2">
							<h6 className="text-section text-uppercase fw-bold mb-2 ">{texto.nav.contactenos}</h6>
							<p className='text-section font-16 text-nav'>
								<i className="bi bi-envelope-at me-2"></i>
								<a className='text-reset' href='mailto:info@softwarehouse.com.ec'>info@softwarehouse.com.ec</a>
							</p>
							<p className='text-section font-16 text-nav'><i className="bi bi-telephone me-2"></i><a className='text-reset' href='tel:+593984538500'>+593 98 453 8500</a></p>
							<p className='text-section font-16 text-nav'><i className="bi bi-telephone me-2"></i><a className='text-reset' href='tel:+593999744957'>+593 99 974 4957</a></p>
						</div>
					</div>
				</div>
			</section>
			<div className="text-section text-center text-white font-14 bg-dark p-4">
				Copyright © 2024 <strong>Soft Warehouse</strong> {texto.footer.derechos}
			</div>
		</footer>
	</>)
}