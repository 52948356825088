import React, { useState } from 'react';
import Spinner from '../../Components/Layout/Spinner';
import { ViewSubDiagrama } from './ViewSubDiagrama';
import useFetch from '../../Hooks/useFetch';


export const ListSubDiagrama = () => {

    const [data, setData] = useState();
    const { loading } = useFetch(`subdiagram/all`, setData);

    return (<>
        <br />
        <br />
        <br />
        {data && data.SubDiagramas?.map((item, index) => (
            <div key={index}>
                <ViewSubDiagrama content={item} index={index} />
                <hr />
            </div>
        ))}
        {loading && <Spinner inFullScreen />}
    </>)
}