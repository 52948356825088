import React, { useContext } from 'react'
import { LanguageContext } from '../../Context/LanguageContext'

export const LemaSection = () => {

	const { texto } = useContext(LanguageContext);

	return (
		<section className="full-box cover section-lema">
			<div className="full-box section-lema-bg p-1">
				<div className="container">
					<p className="text-condensed text-center text-white font-18">
						{texto.inicio.lema}
					</p>
				</div>
			</div>
		</section>
	)
}