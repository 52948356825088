import React, { useContext, useState } from 'react'
import General from '../../Data/General_fit.json';
import fitbank_img from '../../Assets/logo_fitbank_nuevo transparente.png'
import PieChart from '../../Components/Layout/PieChart';
import useFetch from '../../Hooks/useFetch';
import { LanguageContext } from '../../Context/LanguageContext';

export const ModulosFitbank = () => {

	const { langueage, texto } = useContext(LanguageContext);

	const [data, setData] = useState();
	const { loading, error } = useFetch(`diagram/5`, setData, true);

	return (
		<>
			<section id="moduleSection" className='container-fluid justify-content-center'>
				<br />
				<div className='row flex-row-reverse g-1'>
					<figure className='col-xxl-5 col-xl-6 col-lg-12 col-md-12 col-sm-12 align-self-center'>
						<div className='text-center'>
							<img className="" height="100" src={fitbank_img} alt="Fit-Bank" />
						</div>
						{data && <PieChart data={data.Diagrama} />}
						{(loading || error) && <PieChart data={General} />}
					</figure>
					<div className="container col-xxl-7 col-xl-6 col-lg-12 col-md-12 col-sm-12 align-self-center order-first p-2 ">
						<h2 className="title-section text-white font-40">Revolutionizing Banking Systems</h2>
						<hr className="title-divider bg-light" />
						<p className="text-section text-white font-18">Discover  the  pinnacle  of  banking  management  automation  with  <b>FIT-BANK</b>, the most comprehensive integrated core banking system on the market. Tailor-made to streamline operations, enhance profitability, and elevate customer service, <b>FIT-BANK</b> empowers financial institutions to launch new products effortlessly and invigorate their business landscape</p>
						<p className="text-section text-white font-18">By meticulously analyzing both financial and non-financial data, FIT-BANK offers invaluable insights into the profitability of customers, products, branches, and executives. This comprehensive understanding allows institutions to pinpoint areas of success and areas for improvement, enabling strategic corrections and the development of bespoke products and services that cater to specific customer needs.</p>
						<p className="text-section text-white font-18">With a robust focus on customer information management and service excellence, FIT-BANK equips commercial executives with the tools to nurture meaningful customer relationships. The result? Satisfied and loyal customers who trust their institution implicitly, fostering a willingness to explore and adopt new offerings with confidence.</p>
					</div>
				</div>
			</section>
		</>
	)
}