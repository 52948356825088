import React, { useState } from 'react';
import { Footer } from '../../App/Footer';
import useFetch from '../../../Hooks/useFetch';
import Spinner from '../../../Components/Layout/Spinner';
import { ServicesSection } from './ServicesSection';

export const ListServices = () => {

    const [data, setData] = useState();
    const { loading } = useFetch(`service`, setData);

    return (<>
        <br />
        <br />
        <br />
        {data && <>
            <ServicesSection data={data.services}/>
            <br />
            <br />
            <Footer />
        </>
        }
        {loading && <Spinner inFullScreen/>}
    </>)
}