import React, { useState } from 'react';
import { ProductsSection } from './ProductsSection';
import Spinner from '../../../Components/Layout/Spinner';
import { Footer } from '../../App/Footer';
import useFetch from '../../../Hooks/useFetch';

export const ListProduct = () => {

    const [data, setData] = useState();
    const { loading } = useFetch(`product`, setData);

    return (<>
        <br />
        <br />
        <br />
        {data && <>
            <ProductsSection data={data.products} />
            <br />
            <br />
            <Footer />
        </>
        }
        {loading && <Spinner inFullScreen/>}
    </>)
}