import React, { useContext } from "react";
import logo from '../../Assets/logo_softwarehouse_white.svg';
import { LanguageContext } from "../../Context/LanguageContext";

export const MainSection = () => {
	const { texto } = useContext(LanguageContext);

	return (<>
		<section className="hero-container" id="home-section">
			<div className="content-wrapper">
				<img src={logo} height="140" alt="Soft Warehouse" />
				<br />
				<p className="text-medium font-25"><strong className="color-lightBlue font-30">F</strong>INANCIAL <strong className="color-lightBlue font-30">I</strong>NTERNET  <strong className="color-lightBlue font-30">T</strong>ECHNOLOGIES</p>
				<hr />
				<p className="font-18">{texto.inicio.portada.lema}</p>
			</div>
		</section>
	</>
	)
}
