import React from 'react';
import { Footer } from '../App/Footer';
import { AuthorizationSection } from './AuthorizationSection';
import { StructureSection } from './StructureSection';

export const SpesPage = () => {
    return (<>
        <br />
        <br />
        <br />
        <br />
        <AuthorizationSection/>
        <br />
        <br />
        <StructureSection/>
        <br />
        <Footer />
    </>)
}