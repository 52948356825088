import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "../tools/Modal";

const PieChart = ({ data }) => {
    var indexRes = useWindowsSize().index;
    const nivelData1 = data.data.filter((item) => item.nivel === "1"); // Obtener datos niveles 1
    const nivelData2 = data.data.filter((item) => item.nivel === "2"); // Obtener datos niveles 2
    const nivelData3 = data.data.filter((item) => item.nivel === "3"); // Obtener datos niveles 3
    const nivelData4 = data.data.filter((item) => item.nivel === "4"); // Obtener datos niveles 4

    // ! useState para controlar la animacion del texto
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItem2, setSelectedItem2] = useState(null);
    const [selectedItem3, setSelectedItem3] = useState(null);
    const [selectedItem4, setSelectedItem4] = useState(null);

    const [contenido, setContenido] = useState({});
    const [nivel, setnivel] = useState(0);
    const [posicion, setposition] = useState(0);

    // * Primer nivel
    const handleMouseEnter = (index) => {
        setSelectedItem(index);
    };
    const handleMouseLeave = () => {
        setSelectedItem(null);
    };

    // * Segundo nivel
    const handleMouseEnter2 = (index) => {
        setSelectedItem2(index);
    };
    const handleMouseLeave2 = () => {
        setSelectedItem2(null);
    };

    // * Tercer Nivel
    const handleMouseEnter3 = (index) => {
        setSelectedItem3(index);
    };
    const handleMouseLeave3 = () => {
        setSelectedItem3(null);
    };

    // * Cuarto Nivel
    const handleMouseEnter4 = (index) => {
        setSelectedItem4(index);
    };

    const handleMouseLeave4 = () => {
        setSelectedItem4(null);
    };

    return (
        <>
            <Modal content={contenido} posicion={getIntervalIndex(nivel, posicion)} id={data.id}></Modal>
            <Chart id={data.id} key={data.id} className="PieChart">
                <SVG id={data.id} key={data.id} indexRes={indexRes} className="chart">
                    <G indexRes={indexRes}>
                        {nivelData4.map((item, index) => {
                            const porcentaje = 100 / nivelData4.length;
                            const startAngle = index * porcentaje * 3.6;
                            const endAngle = (index + 1) * porcentaje * 3.6;
                            const radius = 280 / indexRes;
                            const startOffset = item.ajustetexto ? `${item.ajustetexto}%` : "10%"; // Variable para inicializar el Offset

                            const isSelected = index === selectedItem4;
                            return (
                                <g id={`${data.id}-nivelData4-${index}`} key={`${data.id}-nivelData4-${index}`}>
                                    <Path
                                        id={`${data.id}-curve2-${index}`}
                                        key={`${data.id}-path-nivelData4-${index}`}
                                        d={describeArc(0, 0, radius, startAngle, endAngle)}
                                        fill={!isSelected ? item.color : (data.coloranimacion ?? "#e9e9e9")}
                                        stroke={!isSelected ? (data.colorborde ?? "#000000") : (data.coloranimacion ?? "#e9e9e9")}
                                        strokeWidth={!isSelected ? "1.5px" : "5px"}
                                        style={isFirefox() ? {} : { transform: "rotate(-90deg)" }}
                                        onMouseEnter={() => handleMouseEnter4(index)}
                                        onMouseLeave={handleMouseLeave4}
                                        onClick={() => { setContenido(item.content); setnivel(nivelData4.length * 2); setposition(index) }}
                                        data-mdb-toggle="modal"
                                        data-mdb-target={`#descriptionModal-${data.id}`}
                                        colorAnimacion={(data.coloranimacion ?? "#e9e9e9")}
                                    />
                                    <TextCurve
                                        id={`${data.id}-textCurve-nivelData4-${index}`}
                                        key={`${data.id}-textCurve-nivelData4-${index}`}
                                        textSize={item.tamanotexto}
                                        indexRes={indexRes}
                                        onMouseEnter={() => handleMouseEnter4(index)}
                                        onMouseLeave={handleMouseLeave4}
                                        onClick={() => { setContenido(item.content); setnivel(nivelData4.length * 2); setposition(index) }}
                                        data-mdb-toggle="modal"
                                        data-mdb-target={`#descriptionModal-${data.id}`}
                                        fill={isSelected ? "#000000" : item.colortexto}
                                    >
                                        <textPath startOffset={startOffset} href={`#${data.id}-curve2-${index}`} >
                                            {item.titulo}
                                        </textPath>
                                    </TextCurve>
                                </g>
                            );
                        })}
                        {nivelData3.map((item, index) => {
                            const porcentaje = 100 / nivelData3.length;
                            const startAngle = index * porcentaje * 3.6;
                            const endAngle = (index + 1) * porcentaje * 3.6;
                            const radius = 235 / indexRes;

                            const isSelected = index === selectedItem3;

                            const lineHeight = 16 / indexRes; // Altura de cada línea de texto
                            const lines = item.titulo.split("\\n"); // Separar el texto por saltos de línea
                            const lineCount = lines.length; // Contar la cantidad de líneas de texto
                            const textPosition = calculateTextPositionnivel3(
                                0,
                                0,
                                radius,
                                startAngle,
                                endAngle,
                                lineHeight,
                                lineCount
                            );
                            return (
                                <g id={`${data.id}-nivelData3-${index}`} key={`${data.id}-nivelData3-${index}`}>
                                    <Path
                                        id={`${data.id}-path-nivelData3-${index}`}
                                        key={`${data.id}-path-nivelData3-${index}`}
                                        d={describeArc(0, 0, radius, startAngle, endAngle)}
                                        fill={!isSelected ? item.color : (data.coloranimacion ?? "#e9e9e9")}
                                        stroke={!isSelected ? (data.colorborde ?? "#000000") : (data.coloranimacion ?? "#e9e9e9")}
                                        strokeWidth={!isSelected ? "1.5px" : "5px"}
                                        onMouseEnter={() => handleMouseEnter3(index)}
                                        onMouseLeave={handleMouseLeave3}
                                        onClick={() => { setContenido(item.content); setnivel(nivelData3.length); setposition(index); }}
                                        data-mdb-toggle="modal"
                                        data-mdb-target={`#descriptionModal-${data.id}`}
                                        colorAnimacion={(data.coloranimacion ?? "#e9e9e9")}
                                    />
                                    {lines.map((line, lineIndex) => (
                                        <Text
                                            id={`${data.id}-text-nivelData3-${index}-${lineIndex}`}
                                            key={`${data.id}-text-nivelData3-${index}-${lineIndex}`}
                                            textSize={item.tamanotexto}
                                            indexRes={indexRes}
                                            x={textPosition.x}
                                            y={
                                                textPosition.y + lineHeight * lineIndex
                                            } /* Ajuste de posición vertical */
                                            onMouseEnter={() => handleMouseEnter3(index)}
                                            onMouseLeave={handleMouseLeave3}
                                            onClick={() => { setContenido(item.content); setnivel(nivelData3.length); setposition(index); }}
                                            data-mdb-toggle="modal"
                                            data-mdb-target={`#descriptionModal-${data.id}`}
                                            fill={isSelected ? "#000000" : item.colortexto}
                                        >
                                            {line}
                                        </Text>
                                    ))}
                                </g>
                            );
                        })}
                        {nivelData2.map((item, index) => {
                            const porcentaje = 100 / nivelData2.length;
                            const startAngle = index * porcentaje * 3.6;
                            const endAngle = (index + 1) * porcentaje * 3.6;
                            const radius = 140 / indexRes;
                            const startOffset = item.ajustetexto ? `${item.ajustetexto}%` : "10%"; // Variable para inicializar el Offset

                            const isSelected = index === selectedItem2;

                            return (
                                <g id={`${data.id}-nivelData2-${index}`} key={`nivelData2-${index}`}>
                                    <Path
                                        id={`${data.id}-curve1-${index}`}
                                        key={`${data.id}-path-nivelData2-${index}`}
                                        d={describeArc(0, 0, radius, startAngle, endAngle)}
                                        fill={!isSelected ? item.color : (data.coloranimacion ?? "#e9e9e9")}
                                        stroke={!isSelected ? (data.colorborde ?? "#000000") : (data.coloranimacion ?? "#e9e9e9")}
                                        strokeWidth={!isSelected ? "1.5px" : "5px"}
                                        onMouseEnter={() => handleMouseEnter2(index)}
                                        onMouseLeave={handleMouseLeave2}
                                        onClick={() => { setContenido(item.content); setnivel(nivelData2.length); setposition(index); }}
                                        data-mdb-toggle="modal"
                                        data-mdb-target={`#descriptionModal-${data.id}`}
                                        colorAnimacion={(data.coloranimacion ?? "#e9e9e9")}
                                    />
                                    <TextCurve
                                        id={`${data.id}-textCurve-nivelData2-${index}`}
                                        key={`${data.id}-textCurve-nivelData2-${index}`}
                                        textSize={item.tamanotexto}
                                        indexRes={indexRes}
                                        onMouseEnter={() => handleMouseEnter2(index)}
                                        onMouseLeave={handleMouseLeave2}
                                        onClick={() => { setContenido(item.content); setnivel(nivelData2.length); setposition(index); }}
                                        data-mdb-toggle="modal"
                                        data-mdb-target={`#descriptionModal-${data.id}`}
                                        fill={isSelected ? "#000000" : item.colortexto}
                                    >
                                        <textPath startOffset={startOffset} href={`#${data.id}-curve1-${index}`}>
                                            {item.titulo}
                                        </textPath>
                                    </TextCurve>
                                </g>
                            );
                        })}
                        {nivelData1.map((item, index) => {
                            const porcentaje = 100 / nivelData1.length;
                            const startAngle = index * porcentaje * 3.6;
                            const endAngle = (index + 1) * porcentaje * 3.6;
                            const radius = 90 / indexRes;

                            const isSelected = index === selectedItem;

                            const lineHeight = 16 / indexRes; // Altura de cada línea de texto
                            const lines = item.titulo.split("\\n"); // Separar el texto por saltos de línea
                            const lineCount = lines.length; // Contar la cantidad de líneas de texto
                            const textPosition = calculateTextPositionnivel1(
                                0,
                                0,
                                radius,
                                startAngle,
                                endAngle,
                                lineHeight,
                                lineCount
                            );

                            return (
                                <g id={`${data.id}-nivelData1-${index}`} key={`${data.id}-nivelData1-${index}`} className="chart">
                                    <Path
                                        id={`${data.id}-path-nivelData1-${index}`}
                                        key={`${data.id}-path-nivelData1-${index}`}
                                        d={describeArc(0, 0, radius, startAngle, endAngle)}
                                        fill={!isSelected ? item.color : (data.coloranimacion ?? "#e9e9e9")}
                                        stroke={!isSelected ? (data.colorborde ?? "#000000") : (data.coloranimacion ?? "#e9e9e9")}
                                        strokeWidth={!isSelected ? "1.5px" : "5px"}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={() => { setContenido(item.content); setnivel(nivelData1.length); setposition(index); }}
                                        data-mdb-toggle="modal"
                                        data-mdb-target={`#descriptionModal-${data.id}`}
                                        colorAnimacion={(data.coloranimacion ?? "#e9e9e9")}
                                    />
                                    {lines.map((line, lineIndex) => (
                                        <Text
                                            id={`${data.id}-text-nivelData1-${index}-${lineIndex}`}
                                            key={`${data.id}-text-nivelData1-${index}-${lineIndex}`}
                                            textSize={item.tamanotexto}
                                            indexRes={indexRes}
                                            x={textPosition.x}
                                            y={
                                                textPosition.y + lineHeight * lineIndex
                                            } /* Ajuste de posición vertical */
                                            onMouseEnter={() => handleMouseEnter(index)}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={() => { setContenido(item.content); setnivel(nivelData1.length); setposition(index); }}
                                            data-mdb-toggle="modal"
                                            data-mdb-target={`#descriptionModal-${data.id}`}
                                            fill={isSelected ? "#000000" : item.colortexto}
                                        >
                                            {line}
                                        </Text>
                                    ))}
                                </g>
                            );
                        })}
                    </G>
                </SVG>
            </Chart>
        </>
    );
};

// * Función auxiliar para describir un arco en un camino SVG
function describeArc(x, y, radius, startAngle, endAngle) {
    const startRad = (startAngle * Math.PI) / 180;
    const endRad = (endAngle * Math.PI) / 180;

    const startX = x + radius * Math.cos(startRad);
    const startY = y + radius * Math.sin(startRad);
    const endX = x + radius * Math.cos(endRad);
    const endY = y + radius * Math.sin(endRad);

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    return `
        M ${startX},${startY}
        A ${radius},${radius} 0 ${largeArcFlag} 1 ${endX},${endY}
        L ${x},${y}
        Z
    `;
}

// Función auxiliar para calcular las coordenadas (x, y) del centro del arco
function calculateTextPositionnivel1(x, y, radius, startAngle, endAngle) {
    const angle = (startAngle + endAngle) / 2;
    var textX = x + radius * Math.cos((angle * Math.PI) / 180);
    var textY = y + radius * Math.sin((angle * Math.PI) / 180);
    textX = textX / 1.6;
    textY = textY / 1.65;
    return { x: textX, y: textY };
}

// Función auxiliar para calcular las coordenadas (x, y) del centro del arco
function calculateTextPositionnivel3(x, y, radius, startAngle, endAngle) {
    const angle = (startAngle + endAngle) / 2;
    var textX = x + radius * Math.cos((angle * Math.PI) / 180);
    var textY = y + radius * Math.sin((angle * Math.PI) / 180);
    textX = textX / 1.25;
    textY = textY / 1.25;
    return { x: textX, y: textY };
}

// ? Definicion de estilos dinamicos

const Chart = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Path = styled.path`
    text-shadow: 5px;
    cursor: pointer;
    &:hover {
        fill: ${(props) => props.colorAnimacion};
        stroke: ${(props) => props.colorAnimacion};
        stroke-width: 5px;
    }
`;

const SVG = styled.svg`
    width: ${(props) => (570 / props.indexRes)}px;
    height: ${(props) => (570 / props.indexRes)}px;
`;

const G = styled.g`
    transform:  translate(${(props) => (285 / props.indexRes)}px, ${(props) => (285 / props.indexRes)}px);
`;

const Text = styled.text`
    font-size: ${(props) => (props.textSize ? props.textSize / props.indexRes : 14 / props.indexRes)}px;
    dominant-baseline: middle;
    text-anchor: middle;
    cursor: pointer;
`;

const TextCurve = styled.text`
    font-size: ${(props) => (props.textSize ? props.textSize / props.indexRes : 14 / props.indexRes)}px;
    dominant-baseline: text-before-edge;
    baseline-shift: sub;
    letter-spacing: ${(props) => (props.indexRes >= 305 ? 3 : 1)}px;
    cursor: pointer;
`;

// Obener el tipo de navegador
const isFirefox = () => {
    const browsertitulo = navigator.userAgent.toLowerCase();
    const result = browsertitulo.includes("firefox") ? true : false;
    return result;
}

// * Función flecha para la obtención del tamaño del dispositivo
const useWindowsSize = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const [index, setIndex] = useState(1);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowSize <= 585 && windowSize > 280) {
            // Calcular propicion
            const proportion = (windowSize - 280) / (305);
            // Calcular el index
            const result = 2 - (proportion * (1));
            // Setear index
            setIndex(parseFloat(result.toFixed(2)));

        } else if (windowSize <= 280) {
            setIndex(2);
        } else {
            setIndex(1);
        }
    }, [windowSize])

    return { windowSize, index };
}

// funcion auxiliar para obtener la pocicion del cuadrante
function getIntervalIndex(size, index) {
    const generateIntervals = (size) => {
        const intervals = [];
        const intervalSize = (size / 4); // Tamaño de cada intervalo

        for (let i = 0; i < 4; i++) {
            const start = i * intervalSize;
            const end = (i + 1) * intervalSize;
            intervals.push([start, end]);
        }
        return intervals;
    };

    const intervals = generateIntervals(size);

    const getIndex = (index, intervals) => {
        for (let i = 0; i < intervals.length; i++) {
            const [start, end] = intervals[i];
            if (index >= start && index < end) {
                return i + 1;
            }
        }
        return 1;
    };

    const num = getIndex(index, intervals);

    return num;
}

export default PieChart;