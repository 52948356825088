import React from 'react';

export const GuaranteesAndSupportSection = () => {
    return(
        <section className='container-fluid min-vh-100 d-flex'>
            <br />
            <div className='container align-self-center'>
                <h2 className='title-section text-center font-40'>Garantía y Soporte</h2>
                <hr className='title-divider bg-primary m-auto my-3'/>
                <p className='text-section text-center font-18'>FIT-BANK tiene una garantía <b>“de por vida”</b> (mientras la Institución Financiera use el sistema) contra defectos de programación, también garantizamos la actualización tecnología a las nuevas versiones de los lenguajes de programación, a las nuevas versiones de la base de datos y del software de los servidores de aplicaciones.
                <br />
                <br />
                Soft Warehouse cuenta con una mesa de ayuda (Help Desk) que funciona <b>24 horas al día los 7 días de la semana</b> para atender y dar soporte a sus clientes.
                <br />
                <br />
                Soft Warehouse desarrolla y <b>entrega sin costo adicional todos los nuevos requerimientos de los Organismos de control</b> a los cuales la Institución Financiera debe entregar estructuras o cualquier tipo de información.
                </p>
            </div>
            <br />
        </section>
    )
}