import React, { useState } from 'react';
import { Footer } from '../../App/Footer';
import useFetch from '../../../Hooks/useFetch';
import Spinner from '../../../Components/Layout/Spinner';
import { TechnologiesSection } from './TechnologiesSection';

export const ListTechnologies = () => {

    const [data, setData] = useState();
    const { loading } = useFetch(`technologie`, setData);

    return (<>
        <br />
        <br />
        <br />
        {data && <>
            <TechnologiesSection data={data.technologies}/>
            <br />
            <br />
            <Footer />
        </>
        }
        {loading && <Spinner inFullScreen/>}
    </>)
}