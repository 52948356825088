import React from "react";
import fitBank from "../../Assets/logo_fitbank_nuevo transparente.png";
import fitCoop from "../../Assets/logo_fitcoop transparente.png";
import fitFinicios from "../../Assets/logo_fitfenicios transparente.png";
import img_fitBank from "../../Assets/Fit-bank.jpg";
import img_fitCoop from "../../Assets/Fit-Coop.jpeg";
import img_fitFinicios from "../../Assets/Fit-fenicios.webp";
import { Link } from "react-router-dom";

export const ProductSection = () => {
  return (<>
    <section className="container-fluid text-center">
      <div className="container-md text-center">
        <h2 className="title-section">Productos</h2>
        <hr className="title-divider bg-primary m-auto mb-30" />
        <br />
      </div>
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-sm-1 row-cols-xs-1 gy-3 justify-content-center">
          <div className="col">
            <div className="card h-100">
              <div className="text-center card-header">
                <img height="75" src={fitCoop} alt="Fit-Coop" loading="lazy" />
              </div>
              <img src={img_fitCoop} alt="Fit-Coop" loading="lazy" />
              <div className="card-body">
                <p className="card-text font-16">Core Financiero para automatizar la gestion financiera de Cooperativas de Ahorro y Crédito, orientado a los requisitos de la SEPS. </p>
                <div className="gap-2 mx-auto">
                  <Link to='/producto/1/FIT-COOP' className="btn btn-primary">Saber mas</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 ">
              <div className="text-center card-header">
              <img height="75" src={fitBank} alt="Fit-Bank" loading="lazy" />
              </div>
                <img src={img_fitBank} alt="Fit-Bank" loading="lazy" />
              <div className="card-body">
                <p className="card-text font-16">Core Financiero para automatizar la gestión financiera de Bancos Comerciales, orientado a mejorar la rentabilidad del negocio.</p>
                <div className="gap-2 mx-auto">
                  <Link to='/producto/2/FIT-BANK' href="#!" className="btn btn-primary">Saber mas</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className="text-center card-header">
                <img height="75" src={fitFinicios} alt="Fit-Fenicios" loading="lazy" />
              </div>
              <img src={img_fitFinicios} alt="Fit-Fenicios" loading="lazy" />
              <div className="card-body">
                <p className="card-text font-16">ERP Financiero Administrativo para automatizar la gestión administrativa y financiera de empresas comerciales e industriales.</p>
                <div className="gap-2 mx-auto">
                  <Link to='/producto/3/FIT-FENICIOS' href="#!" className="btn btn-primary">Saber mas</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
