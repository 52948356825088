import React from 'react';

const Spinner = ({ inFullScreen = false, light = false }) => {

    if (light) {
        return (
            <div className={`${inFullScreen ? 'position-absolute top-50 start-50 translate-middle' : ''} text-center`} id='spinner-container'>
                <br />
                <span className='loader-light'></span>
                <br />
            </div>
        )
    } else {
        return (
            <div className={`${inFullScreen ? 'position-absolute top-50 start-50 translate-middle' : ''} text-center`} id='spinner-container'>
                <br />
                <span className='loader'></span>
                <br />
            </div>
        )
    }
}

export default Spinner;