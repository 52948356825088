import React, { useState } from 'react';
import useFetch from '../../../Hooks/useFetch';
import Spinner from '../../../Components/Layout/Spinner';
import PieChart from '../../../Components/Layout/PieChart'
import ImageComponent from '../../../Components/tools/Content/ImageComponent';
import styled from 'styled-components';

export const MainSection = ({ id, product = {}, logo = {}, img = {} }) => {

  const [data, setData] = useState();
  const { loading, error } = useFetch(`diagram/product/${id}`, setData);

  return (
    <ProductSection className='container-fluid' backgroundImage={`data:${img.nombrearchivo};base64,${img.imagen}`}>
      <br />
      <br />
      <div className='row flex-row-reverse g-1'>
        <figure className='col-xxl-5 col-xl-6 col-lg-12 col-md-12 col-sm-12 align-self-center'>
          {data && <PieChart data={data.Diagrama} />}
          {loading && <Spinner light />}
          {error && <p>{error}</p>}
        </figure>
        <div className="container col-xxl-7 col-xl-6 col-lg-12 col-md-12 col-sm-12 align-self-center text-center order-first">
          {logo != null ?
            <ImageComponent className="" height="80" base64ImageData={logo} alt="Fit-Bank" />
            :
            <h2 className="title-section text-white font-40">{product.nombre}</h2>
          }
          <hr className="title-divider bg-light m-auto mb-30 my-3" />
          <p className="text-section text-white font-16" dangerouslySetInnerHTML={{ __html: product.descripcion }}></p>
        </div>
      </div>
    </ProductSection>
  )
}

// ? Definicion de estilos dinamico 
const ProductSection = styled.section`
  width: 100%;
  background-image: url(${(props) => props.backgroundImage});
  background-position: center;
  background-size: cover;
  background-color: rgba(51, 45, 45, 0.99);
  background-blend-mode: soft-light;
`;