import React from 'react';

import java_logo from '../../Assets/img-tools/Java-logo.png'
import angular_logo from '../../Assets/img-tools/angular-3.svg'
import oracle_logo from '../../Assets/img-tools/Oracle_logo.svg.png'
import maven_logo from '../../Assets/img-tools/Apache_Maven_logo.svg.png'
import Jaspersoft_logo from '../../Assets/img-tools/Jaspersoft.png'
import jboss_logo from '../../Assets/img-tools/jboss.png'
import hibernate_logo from '../../Assets/img-tools/hibernate.png'
import wildfly_logo from '../../Assets/img-tools/wildfly_detail_logo.svg'

export const ToolsSection = () => {
    return (
        <section className='container-fluid '>
            <div className='slider-tools p-2' >
                <div className='tool-slide'>
                    <img src={java_logo} alt="Java logo" loading="lazy" />
                    <img src={angular_logo} alt="Angular logo" loading="lazy" />
                    <img src={oracle_logo} alt="Oracle logo" loading="lazy" />
                    <img src={maven_logo} alt="Maven logo" loading="lazy" />
                    <img src={Jaspersoft_logo} alt="Jaspersoft logo" loading="lazy" />
                    <img src={jboss_logo} alt="Jboss logo" loading="lazy" />
                    <img src={hibernate_logo} alt="Hinernate logo" loading="lazy" />
                    <img src={wildfly_logo} alt="WildFly logo" loading="lazy" />
                </div>
                <div className='tool-slide'>
                    <img src={java_logo} alt="Java logo" loading="lazy" />
                    <img src={angular_logo} alt="Angular logo" loading="lazy" />
                    <img src={oracle_logo} alt="Oracle logo" loading="lazy" />
                    <img src={maven_logo} alt="Maven logo" loading="lazy" />
                    <img src={Jaspersoft_logo} alt="Jaspersoft logo" loading="lazy" />
                    <img src={jboss_logo} alt="Jboss logo" loading="lazy" />
                    <img src={hibernate_logo} alt="Hinernate logo" loading="lazy" />
                    <img src={wildfly_logo} alt="WildFly logo" loading="lazy" />
                </div>
                <div className='tool-slide'>
                    <img src={java_logo} alt="Java logo" loading="lazy" />
                    <img src={angular_logo} alt="Angular logo" loading="lazy" />
                    <img src={oracle_logo} alt="Oracle logo" loading="lazy" />
                    <img src={maven_logo} alt="Maven logo" loading="lazy" />
                    <img src={Jaspersoft_logo} alt="Jaspersoft logo" loading="lazy" />
                    <img src={jboss_logo} alt="Jboss logo" loading="lazy" />
                    <img src={hibernate_logo} alt="Hinernate logo" loading="lazy" />
                    <img src={wildfly_logo} alt="WildFly logo" loading="lazy" />
                </div>
            </div>
        </section>
    )
}


