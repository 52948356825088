import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { LanguageContext } from '../Context/LanguageContext';

function useFetch(route, setData, ignoreError=false) {
    const apiUrl = process.env.REACT_APP_BACKEND_URL;
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const { language } = useContext(LanguageContext);

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await axios.get(`${apiUrl}/${language.toLowerCase()}/${route}`, { headers: { 'accept': "application/json" } });
                setLoading(false);
                res.data.message && setMessage(res.data.message);
                res.data.data && setData(res.data.data);
            } catch (err) {
                setError('Un error a ocurrido');
                console.error(err);
                if (!ignoreError) {
                    if (err.code === 'ERR_NETWORK'  || /^5/.test(err.response.status)) {
                        navigate('/500');
                    } else {
                        navigate('/404');  
                    }
                }
            } finally {
                setLoading(false);
            }
        };
        getData();  
    }, [ignoreError, apiUrl, route, setData, navigate, language]);

    return { message, setMessage, loading, setLoading, error, setError };
}

export default useFetch;
