import React, { useContext } from "react";
import logo from "../../Assets/logo_softwarehouse_large.svg";
import { Link, Outlet, useLocation } from 'react-router-dom';
import { LanguageSelector } from '../../Components/tools/LanguageSelector';
import { LanguageContext } from "../../Context/LanguageContext";

export const NavBar = () => {

	const { texto } = useContext(LanguageContext);
	const location = useLocation();
	const urlActual = location.pathname;
	window.scrollTo(0, 0); // Para reniciar el scroll
	return (
		<>
			<nav className="navbar fixed-top navbar-expand-lg bg-light p-1" id="principal-menu">
				<div className="container-fluid ">
					<button
						className="navbar-toggler "
						type="button"
						data-mdb-toggle="collapse"
						data-mdb-target="#navbar"
						aria-controls="navbar"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<i className="bi bi-list"></i>
					</button>
					<div className="collapse navbar-collapse" id="navbar">
						<hr className="my-2" />
						{urlActual === '/' ? <a href='#home-section' className="navbar-brand btn-menu justify-content-center mx-4" >
							<img
								src={logo}
								height="35"
								alt="Soft Warehouse"
								loading="lazy"
							/>
						</a> : <Link to='/' className="navbar-brand btn-menu justify-content-center mx-4 " >
							<img
								src={logo}
								height="35"
								alt="Soft Warehouse"
								loading="lazy"
							/>
						</Link>}
						<hr className="my-2" />
						<ul className="navbar-nav ms-auto font-16 text-center">
							<li className="nav-item">
								{urlActual === '/' ? <a href='#home-section' className="nav-link text-black" >
									{urlActual === '/' ? <b className="text-primary">{texto.nav.inicio}</b> : <div className="text-nav">{texto.nav.inicio}</div>}
								</a> : <Link to='/' className="nav-link text-black" >
									<div className="text-nav">
										{texto.nav.inicio}
									</div>
								</Link>}
							</li>
							<li className="nav-item">
								<Link to='/nosotros' className="nav-link text-black" >
									{urlActual === '/nosotros' ? <b className="text-primary">{texto.nav.nosotros}</b> : <div className="text-nav">{texto.nav.nosotros}</div>}
								</Link>
							</li>
							<li className="nav-item">
								<Link to='/clientes' className="nav-link text-black" >
									{urlActual === '/clientes' ? <b className="text-primary">{texto.nav.clientes}</b> : <div className="text-nav">{texto.nav.clientes}</div>}
								</Link>
							</li>
							<li className="nav-item">
								<Link to='/demo' className="nav-link text-black" >
									{urlActual === '/demo' ? <b className="text-primary">Demo</b> : <div className="text-nav">Demo</div>}
								</Link>
							</li>
							<li className="nav-item">
                                <Link to='/producto' className="nav-link text-black" >
                                    {urlActual === '/producto' ? <b className="text-primary">{texto.nav.productos}</b> : <div className="text-nav">{texto.nav.productos}</div>}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/estructuras' className="nav-link text-black" >
                                    {urlActual === '/estructuras' ? <b className="text-primary">{texto.nav.estructuras}</b> : <div className="text-nav">{texto.nav.estructuras}</div>}
                                </Link>
                            </li>
							<li className="nav-item">
								{urlActual === '/' ? <a href='#contact-section' className="nav-link text-black" >
									<div className="text-nav">
										{texto.nav.contactenos}
									</div>
								</a> : <Link to='/' className="nav-link text-black" >
									<div className="text-nav">
										{texto.nav.contactenos}
									</div>
								</Link>}
							</li>
							<li className="nav-item mb-1" id="center" >
								<LanguageSelector />
							</li>
						</ul>
					</div>
				</div>
			</nav>
			<main>
				<Outlet />
			</main>
		</>
	);
};
