import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../Hooks/useFetch';
import Spinner from '../../Components/Layout/Spinner';
import { ViewSubDiagrama } from './ViewSubDiagrama';

export const ShowSubDiagrama = () => {

    const { id } = useParams();
    const [diagram, setDiagram] = useState();
    const { loading } = useFetch(`subdiagram/${id}`, setDiagram);

    return (
        <>
            <br />
            <br />
            <br />
            {diagram &&
                <>
                    <ViewSubDiagrama content={diagram.SubDiagrama} />
                    <hr />
                </>
            }
            {loading && <Spinner inFullScreen/>}
            <br />
            <br />
        </>
    )
}