import React from "react";
import { MainSection } from "./MainSection"
import { ProductSection } from "./ProductSection"
import { ModulosSection } from "./ModulosSection";
import { ModulosFitbank } from "./ModulosFitbank";
import { TecnologicSection } from "./TecnologicSection";
import { LemaSection } from "./LemaSection";
import { UsSection } from "./UsSection";
import { ContactUsSection } from "./ContactUsSection";
import { Footer } from "../App/Footer";
import { ToolsSection } from "./ToolsSection";
import { ClientSection } from "./ClientSection";
import { ModuleTable } from "../Modules/ModuleTable";

export const Home = () => {

	return (<>
		<br />
		<MainSection />
		<br />
		<br />
		<ToolsSection />
		<br />
		<br />
		<ModulosSection />
		{/* <ModulosFitbank /> */}
		{/* <br id="products-section" />
		<br />
		<ProductSection />
		<br />
		<br />*/}
		<br />
		<br />
		<TecnologicSection />
		<br />
		<br />
		<ModuleTable />
		<br />
		<br />
		<ClientSection />
		<br id="tecnologic-section" />
		<br />
		<UsSection />
		<br />
		<br />
		<LemaSection />
		<br id="contact-section" />
		<br />
		<ContactUsSection />
		<br />
		<br />
		<Footer />
	</>
	)
}



