import React from 'react';
import { ClientSection } from '../Home/ClientSection';
import { ToolsSection } from '../Home/ToolsSection';
import Spinner from '../../Components/Layout/Spinner';
import { Carrucel } from '../Home/Carrucel';
import { StabilitySection } from '../Content/StabilitySection';
import { GuaranteesAndSupportSection } from '../Content/GuaranteesAndSupportSection';
import { CloudProcesingSection } from '../Content/CloudProcesingSection';
import { LicensesAndInstallationSection } from '../Content/LicensesAndInstallationSection';
import { DeployRequestDemo } from '../DeployRequest/DeployRequestDemo';

export const Pruebas = () => {
    return (
        <>
            <br />
            <DeployRequestDemo/>
            <br />
            <br />
            <StabilitySection/>
            <br />
            <br />
            <GuaranteesAndSupportSection/>
            <br />
            <br />
            <CloudProcesingSection/>
            <br />
            <br />
            <LicensesAndInstallationSection/>
            <br />
            <br />
            <Carrucel/>
            <br />
            <br />
            <ClientSection />
            <br />
            <br />
            <ToolsSection/>
            <br />
            <br />
            <Spinner/>
            <br />
            <br />
        </>
    )
}