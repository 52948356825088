import React from 'react';
import { MissionAndVisionSection } from './MissionAndVisionSection';
import { OurHistorySection } from './OurHistorySection';
import { OurValuesSection } from './OurValuesSection';
// import { OurTeamSection } from './OurTeamSection';
import { Footer } from '../App/Footer';

export const UsPage = () => {
  
  return (
    <>
    <br />
    <br />
    <br />
    <br />
    <MissionAndVisionSection/>
    <br />
    <br />
    <OurHistorySection/>
    <br />
    <br />
    <br />
    <br />
    <OurValuesSection/>
    <br />
    <br />
    {/* <OurTeamSection/>
    <br />
    <br /> */}
    <Footer/>
    </>
  )
}