import React, { useContext } from 'react';

import { LanguageContext } from '../../Context/LanguageContext';

import Banco_pacifico from '../../Assets/img-clients/logos/Banco_pacifico.png';
import Banco_austro from '../../Assets/img-clients/logos/Banco_austro.png';
import conafips from '../../Assets/img-clients/logos/conafips.png';
import vazcredit from '../../Assets/img-clients/logos/vazcredit.png';
import jep from '../../Assets/img-clients/logos/jep.png';
import atuntaqui from '../../Assets/img-clients/logos/atuntaqui.png';
import mushuc_runa from '../../Assets/img-clients/logos/mushuc_runa.png';
import ambato from '../../Assets/img-clients/logos/ambato.png';
import julian from '../../Assets/img-clients/logos/julian.png';
import pilahuintio from '../../Assets/img-clients/logos/pilahuintio.png';
import cooperco from '../../Assets/img-clients/logos/cooperco.png';
import gualaquiza from '../../Assets/img-clients/logos/gualaquiza.png';
import luz_valle from '../../Assets/img-clients/logos/luz_valle.png';
import ccca from '../../Assets/img-clients/logos/ccca.png';
import visandes from '../../Assets/img-clients/logos/visandes.png';
import coop_austro from '../../Assets/img-clients/logos/coop_austro.png';
import educadores from '../../Assets/img-clients/logos/educadores.png';
import cacel from '../../Assets/img-clients/logos/cacel.png';
import san_jorge from '../../Assets/img-clients/logos/san_jorge.png';
import giron from '../../Assets/img-clients/logos/giron.png';
import sierra_centro from '../../Assets/img-clients/logos/sierra_centro.png';
import financredit from '../../Assets/img-clients/logos/financredit.png';
import crecer from '../../Assets/img-clients/logos/crecer.png';
import refider from '../../Assets/img-clients/logos/refider.png';
import angahuana from '../../Assets/img-clients/logos/angahuana.png';
import rios from '../../Assets/img-clients/logos/4_rios.png';
import ars from '../../Assets/img-clients/logos/ars.png';
import club from '../../Assets/img-clients/logos/club.png';
import accion_desarrollo from '../../Assets/img-clients/logos/accion_desarrollo.png';
import san_vicente from '../../Assets/img-clients/logos/san_vicente.png';
import finander from '../../Assets/img-clients/logos/finander.png';
import seguracoop from '../../Assets/img-clients/logos/seguracoop.png';
import sol from '../../Assets/img-clients/logos/sol.png';
import caja from '../../Assets/img-clients/logos/caja.png';
import cacpe from '../../Assets/img-clients/logos/cacpe.png';

import austro_bank from '../../Assets/img-clients/logos/austro_bank.png';
import panafinsa from '../../Assets/img-clients/logos/panafinsa.png';
import fus from '../../Assets/img-clients/logos/fus.png';
import vegacoop from '../../Assets/img-clients/logos/vegacoop.png';

import commonwealth from '../../Assets/img-clients/logos/commonwealth.png';
import compass from '../../Assets/img-clients/logos/compass.png';
import rib from '../../Assets/img-clients/logos/rib.png';
import igb from '../../Assets/img-clients/logos/igb.png';

import banco_comercio from '../../Assets/img-clients/logos/banco_comercio.png';
import fininvest from '../../Assets/img-clients/logos/fininvest.png';

export const ClientSection = () => {

	const { texto } = useContext(LanguageContext);

	return (
		<section id='our-clients-section' className='container-fluid'>
			<div className='container-md text-center p-3'>
				<h2 className='title-section font-40'>{texto.seccion.clientes.titulo}</h2>
				<hr className="title-divider bg-primary m-auto mb-30" />
				<p className='text-section'>{texto.seccion.clientes.parrafo1}</p>
			</div>
			<div className='container-fluid text-center table-responsive'>
				<table className="table table-sm text-center table-bordered border-dark ">
					<thead className="table-dark">
						<tr>
							<th colSpan="5">ECUADOR</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th><img className="" height="50" src={Banco_pacifico} alt="Banco_pacifico" /></th>
							<td><img className="" height="50" src={Banco_austro} alt="Banco_austro" /></td>
							<td><img className="" height="50" src={conafips} alt="conafips" /></td>
							<td><img className="" height="50" src={vazcredit} alt="vazcredit" /></td>
							<td><img className="" height="50" src={jep} alt="jep" /></td>
						</tr>
						<tr>
							<th><img className="" height="50" src={atuntaqui} alt="atuntaqui" /></th>
							<td><img className="" height="50" src={mushuc_runa} alt="mushuc_runa" /></td>
							<td><img className="" height="50" src={ambato} alt="ambato" /></td>
							<td><img className="" height="50" src={julian} alt="julian" /></td>
							<td><img className="" height="50" src={pilahuintio} alt="pilahuintio" /></td>
						</tr>
						<tr>
							<th><img className="" height="50" src={cooperco} alt="cooperco" /></th>
							<td><img className="" height="50" src={gualaquiza} alt="gualaquiza" /></td>
							<td><img className="" height="50" src={luz_valle} alt="luz_valle" /></td>
							<td><img className="" height="50" src={ccca} alt="ccca" /></td>
							<td><img className="" height="50" src={visandes} alt="visandes" /></td>
						</tr>
						<tr>
							<th><img className="" height="50" src={coop_austro} alt="coop_austro" /></th>
							<td><img className="" height="50" src={educadores} alt="educadores" /></td>
							<td><img className="" height="50" src={cacel} alt="cacel" /></td>
							<td><img className="" height="50" src={san_jorge} alt="san_jorge" /></td>
							<td><img className="" height="50" src={giron} alt="giron" /></td>
						</tr>
						<tr>
							<th><img className="" height="50" src={sierra_centro} alt="sierra_centro" /></th>
							<td><img className="" height="50" src={financredit} alt="financredit" /></td>
							<td><img className="" height="50" src={crecer} alt="crecer" /></td>
							<td><img className="" height="50" src={refider} alt="refider" /></td>
							<td><img className="" height="50" src={angahuana} alt="angahuana" /></td>
						</tr>
						<tr>
							<th><img className="" height="60" src={rios} alt="rios" /></th>
							<td><img className="" height="60" src={ars} alt="ars" /></td>
							<td><img className="" height="60" src={club} alt="club" /></td>
							<td><img className="" height="50" src={accion_desarrollo} alt="accion_desarrollo" /></td>
							<td><img className="" height="50" src={san_vicente} alt="san_vicente" /></td>
						</tr>
						<tr>
							<th><img className="" height="40" src={finander} alt="finander" /></th>
							<td><img className="" height="60" src={seguracoop} alt="seguracoop" /></td>
							<td><img className="" height="60" src={sol} alt="sol" /></td>
							<td><img className="" height="50" src={caja} alt="caja_republica" /></td>
							<td><img className="" height="70" src={cacpe} alt="cacpe" /></td>
						</tr>
					</tbody>
				</table>
			</div>
			<br />
			<div className='container-lg text-center table-responsive'>
				<table className="table table-sm text-center table-bordered border-dark ">
					<thead className="table-dark">
						<tr>
							<th colSpan="2">PANAMÁ</th>
							<th>BELICE</th>
							<th>PUERTO RICO</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th><img className="" height="50" src={austro_bank} alt="austro_bank" /></th>
							<td><img className="" height="50" src={panafinsa} alt="panafinsa" /></td>
							<td><img className="" height="50" src={fus} alt="fus" /></td>
							<td><img className="" height="50" src={vegacoop} alt="vegacoop" /></td>
						</tr>
					</tbody>
				</table>
			</div>
			<br />
			<div className='container-lg text-center table-responsive'>
				<table className="table table-sm text-center table-bordered border-dark ">
					<thead className="table-dark">
						<tr>
							<th colSpan="2">DOMINICA</th>
							<th colSpan="2">CURAZAO</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th><img className="" height="50" src={commonwealth} alt="commonwealth" /></th>
							<td><img className="" height="50" src={compass} alt="compass" /></td>
							<td><img className="" height="50" src={rib} alt="rib" /></td>
							<td><img className="" height="50" src={igb} alt="igb" /></td>
						</tr>
					</tbody>
				</table>
			</div>
			<br />
			<div className='container text-center table-responsive'>
				<table className="table table-sm text-center table-bordered border-dark ">
					<thead className="table-dark">
						<tr>
							<th>PERÚ</th>
							<th>ENGLAND UK</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th><img className="" height="60" src={banco_comercio} alt="banco_comercio" /></th>
							<td><img className="" height="60" src={fininvest} alt="fininvest" /></td>
						</tr>
					</tbody>
				</table>
			</div>
		</section>
	)
}