import React, { useContext } from 'react';
import ImageComponent from '../../../Components/tools/Content/ImageComponent';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../../Context/LanguageContext';

export const ProductsSection = ({ data = {} }) => {

	const { texto } = useContext(LanguageContext);

	return (
		<>
			<section className="container-fluid text-center">
				<div className="container-md">
					<h2 className="title-section">{texto.seccion.productos.titulo}</h2>
					<hr className="title-divider bg-primary m-auto mb-30" />
					<p className="text-section  font-16">{texto.seccion.productos.parrafo1}</p>
				</div>
				<div className="container">
					<div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-1 row-cols-xs-1 row-cols-1 g-3 justify-content-center">
						{data?.map((item, index) => (
							<div key={index} className="col">
								<div className="card h-100">
									<div className="text-center card-header" >
										{item.logo ?
											<ImageComponent height="75" base64ImageData={item.logo} alt={`logo-${item.nombre}`} />
											:
											<h2 className="title-section font-40">{item.nombre}</h2>
										}
									</div>
									{item.imagen && <ImageComponent base64ImageData={item.imagen} alt={`Image-${item.nombre}`} />}
									<div className="card-body">
										<p className="card-text font-16" dangerouslySetInnerHTML={{ __html: item.descripcioncorta }}></p>
										<div className="gap-2 mx-auto">
											<Link to={`/producto/${item.id}/${item.nombre}`} className="btn btn-primary">{texto.acciones.saber_mas}</Link>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
		</>
	)
}