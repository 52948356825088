import React from 'react';

import escabilidad from '../../Assets/img-content/escabilidad.png'

export const StabilitySection = () => {
    return(
        <section className='container-fluid'>
            <br />
            <div className='container'>
                <h2 className='title-section text-center font-40'>Escabilidad</h2>
                <hr className='title-divider bg-primary m-auto my-3'/>
                <p className='text-section text-center font-18'>FIT-BANK tiene una capacidad de crecimiento casi ilimitada, puede instalarse comenzando en un solo servidor (para instituciones muy pequeñas) y desde allí ir escalando, conforme las necesidades y tamaño de la institución, hasta una configuración con “n” switchs y servidores web, “n” balanceadores de carga, “n” servidores de aplicación clusterizados, “n” servidores de base de datos clusterizados, con capacidad de manejar volúmenes de millones de clientes y millones de transacciones diarias.</p>
            </div>
            <br />
            <div className='container'>
                <img className='img-fluid' width={5000} src={escabilidad} alt="Escabilidad" loading='lazy' />
            </div>
        </section>
    )
}