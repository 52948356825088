import React, { useContext, useState } from 'react'
import { LanguageContext } from '../../Context/LanguageContext'
import { Modal } from '../../Components/tools/Modal';

export const TecnologicSection = () => {

	const { texto } = useContext(LanguageContext);
	const [contenido, setContenido] = useState({});
	const createContent = (item, nameIcon) => {
		return {
			titulo: item.titulo,
			descripcion: item.descripcion,
			icon: nameIcon,
			height: "font-70"
		}
	}

	return (<>
		<Modal content={contenido} posicion={0}></Modal>
		<section id='tecnologicSection' className='container-fluid'>
			<br />
			<div className="container-md">
				<h2 className="title-section text-center text-white font-35">{texto.inicio.tecnologias.titulo}</h2>
				<hr className="title-divider bg-light m-auto mb-30" />
				<p className="text-section text-center  text-white font-18">{texto.inicio.tecnologias.parrafo1}</p>
			</div>
			<br />
			<div className="container">
				<div className="row row-cols-1 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-xs-1 gy-3 justify-content-center">
					<div className="col">
						<div className="card text-center border border-dark mb-1 h-100"
							onClick={() => setContenido(createContent(texto.inicio.tecnologias.web, "globe"))}
							data-mdb-toggle="modal"
							data-mdb-target={`#descriptionModal-0`}>
							<div className="card-body">
								<i className="bi bi-globe font-45"></i>
								<h5 className="card-title font-16">{texto.inicio.tecnologias.web.titulo}</h5>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card text-center border border-dark mb-1 h-100"
							onClick={() => setContenido(createContent(texto.inicio.tecnologias.compatibilidad, "window-sidebar"))}
							data-mdb-toggle="modal"
							data-mdb-target={`#descriptionModal-0`}>
							<div className="card-body">
								<i className="bi bi-window-sidebar font-45"></i>
								<h5 className="card-title font-16">{texto.inicio.tecnologias.compatibilidad.titulo}</h5>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card text-center border border-dark mb-1 h-100"
							onClick={() => setContenido(createContent(texto.inicio.tecnologias.multidioma, "chat-square-text"))}
							data-mdb-toggle="modal"
							data-mdb-target={`#descriptionModal-0`}>
							<div className="card-body">
								<i className="bi bi-chat-square-text font-45"></i>
								<h5 className="card-title font-16">{texto.inicio.tecnologias.multidioma.titulo}</h5>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card text-center border border-dark mb-1 h-100"
							onClick={() => setContenido(createContent(texto.inicio.tecnologias.multicompania, "buildings"))}
							data-mdb-toggle="modal"
							data-mdb-target={`#descriptionModal-0`}>
							<div className="card-body">
								<i className="bi bi-buildings font-45"></i>
								<h5 className="card-title font-16">{texto.inicio.tecnologias.multicompania.titulo}</h5>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card text-center border border-dark mb-1 h-100"
							onClick={() => setContenido(createContent(texto.inicio.tecnologias.producto, "columns-gap"))}
							data-mdb-toggle="modal"
							data-mdb-target={`#descriptionModal-0`}>
							<div className="card-body">
								<i className="bi bi-columns-gap font-45"></i>
								<h5 className="card-title font-16">{texto.inicio.tecnologias.producto.titulo}</h5>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card text-center border border-dark mb-1 h-100"
							onClick={() => setContenido(createContent(texto.inicio.tecnologias.no_stop, "play-circle"))}
							data-mdb-toggle="modal"
							data-mdb-target={`#descriptionModal-0`}>
							<div className="card-body">
								<i className="bi bi-play-circle font-45"></i>
								<h5 className="card-title font-16">{texto.inicio.tecnologias.no_stop.titulo}</h5>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card text-center border border-dark mb-1 h-100"
							onClick={() => setContenido(createContent(texto.inicio.tecnologias.inteligencia, "lightbulb"))}
							data-mdb-toggle="modal"
							data-mdb-target={`#descriptionModal-0`}>
							<div className="card-body">
								<i className="bi bi-lightbulb font-45"></i>
								<h5 className="card-title font-16">{texto.inicio.tecnologias.inteligencia.titulo}</h5>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card text-center border border-dark mb-1 h-100"
							onClick={() => setContenido(createContent(texto.inicio.tecnologias.reporte, "file-earmark-richtext"))}
							data-mdb-toggle="modal"
							data-mdb-target={`#descriptionModal-0`}>
							<div className="card-body">
								<i className="bi bi-file-earmark-richtext font-45"></i>
								<h5 className="card-title font-16">{texto.inicio.tecnologias.reporte.titulo}</h5>
							</div>
						</div>
					</div>

					<div className="col">
						<div className="card text-center border border-dark mb-3 h-100"
							onClick={() => setContenido(createContent(texto.inicio.tecnologias.soporte, "gear"))}
							data-mdb-toggle="modal"
							data-mdb-target={`#descriptionModal-0`}>
							<div className="card-body">
								<i className="bi bi-gear font-45"></i>
								<h5 className="card-title font-16">{texto.inicio.tecnologias.soporte.titulo}</h5>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card text-center border border-dark mb-3 h-100"
							onClick={() => setContenido(createContent(texto.inicio.tecnologias.seguridad, "shield-check"))}
							data-mdb-toggle="modal"
							data-mdb-target={`#descriptionModal-0`}>
							<div className="card-body">
								<i className="bi bi-shield-check font-45"></i>
								<h5 className="card-title font-16">{texto.inicio.tecnologias.seguridad.titulo}</h5>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card text-center border border-dark mb-3 h-100"
							onClick={() => setContenido(createContent(texto.inicio.tecnologias.auditoria, "clock-history"))}
							data-mdb-toggle="modal"
							data-mdb-target={`#descriptionModal-0`}>
							<div className="card-body">
								<i className="bi bi-clock-history font-45"></i>
								<h5 className="card-title font-16">{texto.inicio.tecnologias.auditoria.titulo}</h5>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card text-center border border-dark mb-1 h-100"
							onClick={() => setContenido(createContent(texto.inicio.tecnologias.nube, "cloud-upload"))}
							data-mdb-toggle="modal"
							data-mdb-target={`#descriptionModal-0`}>
							<div className="card-body">
								<i className="bi bi-cloud-upload font-45"></i>
								<h5 className="card-title font-16">{texto.inicio.tecnologias.nube.titulo}</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
			<br />
		</section>
	</>)
}