import React from 'react';
import { ClientSection } from './ClientSection';

import { Footer } from '../App/Footer';

export const ClientPage = () => {

    return (
        <>
            <br />
            <br />
            <ClientSection />
            <br />
            <Footer />
        </>
    )
}