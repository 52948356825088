import React, { useState } from "react";
import PieChart from "../../Components/Layout/PieChart";
import Spinner from "../../Components/Layout/Spinner";
import useFetch from "../../Hooks/useFetch";


export const ListDiagramas = () => {

    const [data, setData] = useState();
    const { loading } = useFetch(`diagram/all`, setData);

    return (
        <>
            <br />
            <br />
            <br />
            {data && data.Diagramas?.map((item, index) => (
                <div key={index}>
                    <h1>{item.titulo}</h1>
                    <PieChart data={item} />
                    <hr />
                </div>
            ))}
            {loading && <Spinner inFullScreen/>}
        </>
    );
};
