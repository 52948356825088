import React, { useState } from 'react';
import { ModulesSection } from './ModulesSection';
import { Footer } from '../../App/Footer';
import useFetch from '../../../Hooks/useFetch';
import Spinner from '../../../Components/Layout/Spinner';

export const ListModules = () => {

    const [data, setData] = useState();
    const { loading } = useFetch(`module`, setData);

    return (<>
        <br />
        <br />
        <br />
        {data && <>
            <ModulesSection data={data.modules} />
            <br />
            <br />
            <Footer />
        </>
        }
        {loading && <Spinner inFullScreen />}
    </>)
}