import React, { useEffect, useState } from 'react';
import { MainSection } from './MainSection';
import { StructureSection } from './StructureSection';
// import { FeatureSection } from './FeatureSection';
import { useParams } from "react-router-dom";
import useFetch from '../../../Hooks/useFetch';
import Spinner from '../../../Components/Layout/Spinner';
import { Footer } from '../../App/Footer';

export const ShowProduct = () => {
    const { id } = useParams();
    const [product, setProduct] = useState();
    const { loading, setLoading } = useFetch(`product/${id}`, setProduct);

    useEffect(() => {
        setProduct();
        setLoading(true);
    }, [id, setLoading]);
    
    return (
        <>
            <br />
            {product &&
                <>
                    <MainSection id={product.product.id} product={product.product} logo={product.img_log} img={product.img} />
                    <br />
                    {product.structures && product.structures.length > 0 && (
                        <StructureSection
                            name={product.product.nombre}
                            type={product.product.cpwtipocontenido}
                            structures={product.structures}
                        />
                    )}
                    <br />
                    <Footer />
                    {/* <FeatureSection/> */}
                </>
            }
            {loading && <Spinner inFullScreen />}
        </>
    )
}