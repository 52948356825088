import React, { useContext } from 'react';
import { LanguageContext } from '../../Context/LanguageContext';

export const OurHistorySection = () => {

	const { texto } = useContext(LanguageContext);

	return (
		<section id='our-history-section' className='container-fluid'>
			<div className='container-md text-center p-3'>
				<h2 className='title-section font-40'>{texto.seccion.nosotros.historia.titulo}</h2>
				<hr className="title-divider bg-primary m-auto mb-30" />
				<p className='text-section'>{texto.seccion.nosotros.historia.parrafo1}</p>
			</div>
			<br />
			<div className='container-md bg-dark text-white text-center '>
				<div className='row  row-cols-lg-4 row-cols-1 row-cols-md-2 p-3 row-cols-sm-2 row-cols-xs-1 gy-3 justify-content-center'>
					<div className='col'>
						{/* <i className="bi bi-calendar-check font-20"></i> */}
						<h2 className='title-text text-white font-50'>2002</h2>
						<hr className="title-divider m-auto mb-30" />
						<p className='font-14'>{texto.seccion.nosotros.historia.fundado}</p>
					</div>
					<div className='col'>
						{/* <i className="bi bi-bank font-20"></i> */}
						<h2 className='title-text text-white font-50'>+40</h2>
						<hr className="title-divider m-auto mb-30" />
						<p className='font-14'>{texto.seccion.nosotros.historia.instituciones}</p>
					</div>
					<div className='col'>
						{/* <i className="bi bi-globe-americas font-20"></i> */}
						<h2 className='title-text text-white font-50'>+8</h2>
						<hr className="title-divider m-auto mb-30" />
						<p className='font-14'>{texto.seccion.nosotros.historia.paises}</p>
					</div>
					<div className='col'>
						{/* <i className="bi bi-award font-20"></i> */}
						<h2 className='title-text text-white font-50'>+20</h2>
						<hr className="title-divider m-auto mb-30" />
						<p className='font-14'>{texto.seccion.nosotros.historia.anios}</p>
					</div>
				</div>
			</div>
		</section>
	)
}