import React, { useEffect, useState } from 'react';
import QuaPieChart from '../../Components/Layout/QuaPieChart';
import SubPieChart from '../../Components/Layout/SubPieChart';
import ImageComponent from '../../Components/tools/Content/ImageComponent';

export const ViewSubDiagrama = ({ content = {}, index = 0 }) => {

    const [po, setPo] = useState(0);

    const [subContent, setSubContent] = useState({});

    useEffect(() => {
        setSubContent({});
    }, [content]);

    const getPosition = (posicion) => {

        posicion = parseInt(posicion);
        // if (content.subContent.length > 6 && (posicion === 1 || posicion === 2)) { posicion = 5 }
        // if (content.subContent.length > 6 && (posicion === 3 || posicion === 4)) { posicion = 6 }
        // if (content.subContent.length > 8) { posicion = 0 }  

        switch (posicion) {
            case 1:
                return (
                    <div className='row g-1'>
                        <figure className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                            <QuaPieChart data={content} onSubContent={setSubContent} position={posicion} />
                        </figure>
                        <div className="container col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 align-self-center p-2 ">
                            {Object.entries(subContent).length === 0 ?
                                (<>
                                    <h2 className="title-section text-center font-40">{content.titulo}</h2>
                                    <hr className="title-divider bg-primary m-auto" />
                                    <br />
                                </>) : (<>
                                    {subContent.content.titulo && <h2 className="text-section font-18 fw-bold">{subContent.content.titulo}</h2>}
                                    {subContent.content.nombre && <h2 className="text-section font-18 fw-bold">{subContent.content.nombre}</h2>}
                                    <hr className="title-divider bg-primary" />
                                    <p className='text-section font-16' dangerouslySetInnerHTML={{ __html: subContent.content.descripcion }}></p>
                                    {subContent.content.imagen && <figure className='figure'>
                                        <ImageComponent base64ImageData={subContent.content.imagen} className='figure-img img-fluid img-cover rounded h-75' imgDefault={false} />
                                    </figure>}
                                </>)}
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className='row flex-row-reverse g-1'>
                        <figure className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                            {/* <SubPieChart data={content} onSubContent={setSubContent}/> */}
                            <QuaPieChart data={content} onSubContent={setSubContent} position={posicion} />
                        </figure>
                        <div className="container col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 align-self-center p-2 ">
                            {Object.entries(subContent).length === 0 ?
                                (<>
                                    <h2 className="title-section text-center font-40">{content.titulo}</h2>
                                    <hr className="title-divider bg-primary m-auto" />
                                    <br />
                                </>) : (<>
                                    {subContent.content.titulo && <h2 className="text-section font-18 fw-bold">{subContent.content.titulo}</h2>}
                                    {subContent.content.nombre && <h2 className="text-section font-18 fw-bold">{subContent.content.nombre}</h2>}
                                    <hr className="title-divider bg-primary" />
                                    <p className='text-section font-16' dangerouslySetInnerHTML={{ __html: subContent.content.descripcion }}></p>
                                    {subContent.content.imagen && <figure className='figure'>
                                        <ImageComponent base64ImageData={subContent.content.imagen} className='figure-img img-fluid img-cover rounded h-75' imgDefault={false} />
                                    </figure>}
                                </>)}
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className='row g-1'>
                        <div className="container col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 align-self-center p-2 ">
                            {Object.entries(subContent).length === 0 ?
                                (<>
                                    <h2 className="title-section text-center font-40">{content.titulo}</h2>
                                    <hr className="title-divider bg-primary m-auto" />
                                    <br />
                                </>) : (<>
                                    {subContent.content.titulo && <h2 className="text-section font-18 fw-bold">{subContent.content.titulo}</h2>}
                                    {subContent.content.nombre && <h2 className="text-section font-18 fw-bold">{subContent.content.nombre}</h2>}
                                    <hr className="title-divider bg-primary" />
                                    <p className='text-section font-16' dangerouslySetInnerHTML={{ __html: subContent.content.descripcion }}></p>
                                    {subContent.content.imagen && <figure className='figure'>
                                        <ImageComponent base64ImageData={subContent.content.imagen} className='figure-img img-fluid img-cover rounded h-75' imgDefault={false} />
                                    </figure>}
                                </>)}
                        </div>
                        <figure className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                            <QuaPieChart data={content} onSubContent={setSubContent} position={posicion} />
                        </figure>
                    </div>
                );
            case 4:
                return (
                    <div className='row flex-row-reverse g-1'>
                        <div className="container col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 align-self-center p-2 ">
                            {Object.entries(subContent).length === 0 ?
                                (<>
                                    <h2 className="title-section text-center font-40">{content.titulo}</h2>
                                    <hr className="title-divider bg-primary m-auto" />
                                    <br />
                                </>) : (<>
                                    {subContent.content.titulo && <h2 className="text-section font-18 fw-bold">{subContent.content.titulo}</h2>}
                                    {subContent.content.nombre && <h2 className="text-section font-18 fw-bold">{subContent.content.nombre}</h2>}
                                    <hr className="title-divider bg-primary" />
                                    <p className='text-section font-16' dangerouslySetInnerHTML={{ __html: subContent.content.descripcion }}></p>
                                    {subContent.content.imagen && <figure className='figure'>
                                        <ImageComponent base64ImageData={subContent.content.imagen} className='figure-img img-fluid img-cover rounded h-75' imgDefault={false} />
                                    </figure>}
                                </>)}
                        </div>
                        <figure className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                            <QuaPieChart data={content} onSubContent={setSubContent} position={posicion} />
                        </figure>
                    </div>
                );
            case 5:
                return (
                    <div className='row g-1'>
                        <figure className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                            <QuaPieChart data={content} onSubContent={setSubContent} position={posicion} />
                        </figure>
                        <div className="container col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 align-self-center p-2 ">
                            {Object.entries(subContent).length === 0 ?
                                (<>
                                    <h2 className="title-section text-center font-40">{content.titulo}</h2>
                                    <hr className="title-divider bg-primary m-auto" />
                                    <br />
                                </>) : (<>
                                    {subContent.content.titulo && <h2 className="text-section text-center font-18 fw-bold">{subContent.content.titulo}</h2>}
                                    {subContent.content.nombre && <h2 className="text-section text-center font-18 fw-bold">{subContent.content.nombre}</h2>}
                                    <hr className="title-divider bg-primary m-auto" />
                                    <p className='text-section text-center font-16' dangerouslySetInnerHTML={{ __html: subContent.content.descripcion }}></p>
                                    {subContent.content.imagen && <figure className='text-center'>
                                        <ImageComponent base64ImageData={subContent.content.imagen} className='figure-img img-fluid img-cover rounded h-75' imgDefault={false} />
                                    </figure>}
                                </>)}
                        </div>
                    </div>
                );
            case 6:
                return (
                    <div className='row g-1'>
                        <div className="container col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 align-self-center p-2 ">
                            {Object.entries(subContent).length === 0 ?
                                (<>
                                    <h2 className="title-section text-center font-40">{content.titulo}</h2>
                                    <hr className="title-divider bg-primary m-auto" />
                                    <br />
                                </>) : (<>
                                    {subContent.content.titulo && <h2 className="text-section text-center font-18 fw-bold">{subContent.content.titulo}</h2>}
                                    {subContent.content.nombre && <h2 className="text-section text-center font-18 fw-bold">{subContent.content.nombre}</h2>}
                                    <hr className="title-divider bg-primary m-auto" />
                                    <p className='text-section text-center font-16' dangerouslySetInnerHTML={{ __html: subContent.content.descripcion }}></p>
                                    {subContent.content.imagen && <figure className='text-center'>
                                        <ImageComponent base64ImageData={subContent.content.imagen} className='figure-img img-fluid img-cover rounded h-75' imgDefault={false} />
                                    </figure>}
                                </>)}
                        </div>
                        <figure className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                            <QuaPieChart data={content} onSubContent={setSubContent} position={posicion} />
                        </figure>
                    </div>
                );
            default:
                return (
                    <div className='row g-1'>
                        <figure className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                            <SubPieChart data={content} onSubContent={setSubContent} />
                            {/* <QuaPieChart data={content} onSubContent={setSubContent} position={posicion} /> */}
                        </figure>
                        <div className="container col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 align-self-center order-first p-2 ">
                            {Object.entries(subContent).length === 0 ?
                                (<>
                                    <h2 className="title-section text-center font-40">{content.titulo}</h2>
                                    <hr className="title-divider bg-primary m-auto" />
                                    <br />
                                </>) : (<>
                                    {subContent.content.titulo && <h2 className="text-section font-18 fw-bold">{subContent.content.titulo}</h2>}
                                    {subContent.content.nombre && <h2 className="text-section font-18 fw-bold">{subContent.content.nombre}</h2>}
                                    <hr className="title-divider bg-primary" />
                                    <p className='text-section font-16' dangerouslySetInnerHTML={{ __html: subContent.content.descripcion }}></p>
                                    {subContent.content.imagen && <figure className='figure'>
                                        <ImageComponent base64ImageData={subContent.content.imagen} className='figure-img img-fluid img-cover rounded h-75' imgDefault={false} />
                                    </figure>}
                                </>)}
                        </div>
                    </div>
                );
        }
    }

    return (
        <>
            <div key={index}>
                <div className="container text-center">
                    <p>Posición del Sub Diagrama de <b>{content.titulo}</b></p>
                    <div>
                        <label className="visually-hidden form-control" htmlFor="inlineFormSelectPref">Preference</label>
                        <select className="select" value={po} onChange={(e) => setPo(e.target.value)} >
                            <option value={0}>0</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                        </select>
                    </div>
                </div>
                <hr />
                <div className="container-fluid">
                    {content.subContent && getPosition(po)}
                </div>
            </div >
        </>
    )
}