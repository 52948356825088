import React, { useContext } from 'react'
import nosotro_img from '../../Assets/img/pexels-photo-6476255.webp'
import { LanguageContext } from '../../Context/LanguageContext'
import { Link } from 'react-router-dom'

export const UsSection = () => {

	const { texto } = useContext(LanguageContext);

	return (<>
		<section id='us' className='container-fluid'>
			<div className='row g-1'>
				<div className="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 align-self-center p-4">
					<h2 className="title-section font-40">{texto.inicio.nosotros.titulo}</h2>
					<hr className="title-divider bg-primary" />
					<p className="text-section font-18">{texto.inicio.nosotros.parrafo1}</p>
					<div className=" gap-2 p-1">
						<Link to='/nosotros' className="btn btn-primary">{texto.acciones.saber_mas}</Link>
					</div>
				</div>
				<figure className='col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 p-3'>
					<img src={nosotro_img} className='figure-img img-fluid img-cover rounded shadow-3 h-100' alt="Nosotros" loading="lazy" />
				</figure>
			</div>
		</section>
	</>)
}