import React, { useState } from "react";
import PieChart from "../../Components/Layout/PieChart";
import useFetch from '../../Hooks/useFetch';
import Spinner from "../../Components/Layout/Spinner";
import { useParams } from "react-router-dom";

export const ShowDiagrama = () => {
    const { id } = useParams();
    const [diagram, setDiagram] = useState();
    const { loading } = useFetch(`diagram/${id}`, setDiagram);

    return (
        <>
            <br />
            <br />
            <br />
            {diagram &&
                <>
                    <h1>{diagram.Diagrama.titulo}</h1>
                    <PieChart data={diagram.Diagrama} />
                    <hr />
                </>
            }
            {loading && <Spinner inFullScreen />}
            <br />
            <br />
        </>
    );
};