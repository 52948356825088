import React, { useEffect, useState } from 'react';
import SubPieChart from '../../Components/Layout/SubPieChart';

export const ViewStructure = ({ content = {}, index = 0 }) => {

    const [subContent, setSubContent] = useState({});

    useEffect(() => {
        setSubContent({});
    }, [content]);

    return (<>
        {(index % 2) === 0 ? <div className='row g-1'>
            <figure className='col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12'>
                <SubPieChart data={content} onSubContent={setSubContent} />
                {/* <QuaPieChart data={content} onSubContent={setSubContent} position={posicion} /> */}
            </figure>
            <div className="container col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 align-self-center p-3">
                {Object.entries(subContent).length === 0 ?
                    (<>
                        <h2 className="title-section text-center font-40">{content.titulo}</h2>
                        <hr className="title-divider bg-primary m-auto" />
                        <br />
                    </>) : (<>
                        {subContent.content.titulo && <h2 className="text-section font-18 fw-bold">{subContent.content.titulo}</h2>}
                        {subContent.content.nombre && <h2 className="text-section font-18 fw-bold">{subContent.content.nombre}</h2>}
                        <hr className="title-divider bg-primary w-auto" />
                        <p className='text-section font-16' dangerouslySetInnerHTML={{ __html: subContent.content.descripcioncorta }}></p>
                    </>)}
            </div>
        </div> : <div className='row flex-row-reverse g-1'>
            <figure className='col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12'>
                <SubPieChart data={content} onSubContent={setSubContent} />
                {/* <QuaPieChart data={content} onSubContent={setSubContent} position={posicion} /> */}
            </figure>
            <div className="container col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 align-self-center p-3">
                {Object.entries(subContent).length === 0 ?
                    (<>
                        <h2 className="title-section text-center font-40">{content.titulo}</h2>
                        <hr className="title-divider bg-primary m-auto" />
                        <br />  
                    </>) : (<>
                        {subContent.content.titulo && <h2 className="text-section font-18 fw-bold">{subContent.content.titulo}</h2>}
                        {subContent.content.nombre && <h2 className="text-section font-18 fw-bold">{subContent.content.nombre}</h2>}
                        <hr className="title-divider bg-primary w-auto" />
                        <p className='text-section font-16' dangerouslySetInnerHTML={{ __html: subContent.content.descripcioncorta }}></p>
                    </>)}
            </div>  
        </div>}
    </>)
}