import React, { useEffect, useState } from 'react';
import SubPieChart from '../Layout/SubPieChart';
import QuaPieChart from '../Layout/QuaPieChart';
import ImageComponent from './Content/ImageComponent';

export const Modal = ({ content = {}, posicion = 0, id = 0 }) => {

    const [subContent, setSubContent] = useState({});
    useEffect(() => {
        setSubContent({});
    }, [content]);

    const getPosition = (posicion) => {
        if (content.subContent.length > 6 && (posicion === 1 || posicion === 2)) { posicion = 5 }
        if (content.subContent.length > 6 && (posicion === 3 || posicion === 4)) { posicion = 6 }
        if (content.subContent.length > 8) { posicion = 0 }

        switch (posicion) {
            case 1:
                return (
                    <div className='row g-1'>
                        <figure className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                            <QuaPieChart data={content} onSubContent={setSubContent} position={posicion} />
                        </figure>
                        <div className="container col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 align-self-center p-2 ">
                            {Object.entries(subContent).length === 0 ?
                                (<>
                                    <h2 className="title-section text-center font-40">{content.titulo}</h2>
                                    <hr className="title-divider bg-primary m-auto" />
                                    <br />
                                </>) : (<>
                                    {subContent.content.titulo && <h2 className="text-section font-18 fw-bold">{subContent.content.titulo}</h2>}
                                    {subContent.content.nombre && <h2 className="text-section font-18 fw-bold">{subContent.content.nombre}</h2>}
                                    <hr className="title-divider bg-primary" />
                                    <p className='text-section font-16' dangerouslySetInnerHTML={{ __html: subContent.content.descripcion }}></p>
                                    {subContent.content.imagen && <figure className='figure'>
                                        <ImageComponent base64ImageData={subContent.content.imagen} className='figure-img img-fluid img-cover rounded h-75' imgDefault={false} />
                                    </figure>}
                                </>)}
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className='row flex-row-reverse g-1'>
                        <figure className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                            {/* <SubPieChart data={content} onSubContent={setSubContent}/> */}
                            <QuaPieChart data={content} onSubContent={setSubContent} position={posicion} />
                        </figure>
                        <div className="container col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 align-self-center p-2 ">
                            {Object.entries(subContent).length === 0 ?
                                (<>
                                    <h2 className="title-section text-center font-40">{content.titulo}</h2>
                                    <hr className="title-divider bg-primary m-auto" />
                                    <br />
                                </>) : (<>
                                    {subContent.content.titulo && <h2 className="text-section font-18 fw-bold">{subContent.content.titulo}</h2>}
                                    {subContent.content.nombre && <h2 className="text-section font-18 fw-bold">{subContent.content.nombre}</h2>}
                                    <hr className="title-divider bg-primary" />
                                    <p className='text-section font-16' dangerouslySetInnerHTML={{ __html: subContent.content.descripcion }}></p>
                                    {subContent.content.imagen && <figure className='figure'>
                                        <ImageComponent base64ImageData={subContent.content.imagen} className='figure-img img-fluid img-cover rounded h-75' imgDefault={false} />
                                    </figure>}
                                </>)}
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className='row g-1'>
                        <div className="container col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 align-self-center p-2 ">
                            {Object.entries(subContent).length === 0 ?
                                (<>
                                    <h2 className="title-section text-center font-40">{content.titulo}</h2>
                                    <hr className="title-divider bg-primary m-auto" />
                                    <br />
                                </>) : (<>
                                    {subContent.content.titulo && <h2 className="text-section font-18 fw-bold">{subContent.content.titulo}</h2>}
                                    {subContent.content.nombre && <h2 className="text-section font-18 fw-bold">{subContent.content.nombre}</h2>}
                                    <hr className="title-divider bg-primary" />
                                    <p className='text-section font-16' dangerouslySetInnerHTML={{ __html: subContent.content.descripcion }}></p>
                                    {subContent.content.imagen && <figure className='figure'>
                                        <ImageComponent base64ImageData={subContent.content.imagen} className='figure-img img-fluid img-cover rounded h-75' imgDefault={false} />
                                    </figure>}
                                </>)}
                        </div>
                        <figure className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                            <QuaPieChart data={content} onSubContent={setSubContent} position={posicion} />
                        </figure>
                    </div>
                );
            case 4:
                return (
                    <div className='row flex-row-reverse g-1'>
                        <div className="container col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 align-self-center p-2 ">
                            {Object.entries(subContent).length === 0 ?
                                (<>
                                    <h2 className="title-section text-center font-40">{content.titulo}</h2>
                                    <hr className="title-divider bg-primary m-auto" />
                                    <br />
                                </>) : (<>
                                    {subContent.content.titulo && <h2 className="text-section font-18 fw-bold">{subContent.content.titulo}</h2>}
                                    {subContent.content.nombre && <h2 className="text-section font-18 fw-bold">{subContent.content.nombre}</h2>}
                                    <hr className="title-divider bg-primary" />
                                    <p className='text-section font-16' dangerouslySetInnerHTML={{ __html: subContent.content.descripcion }}></p>
                                    {subContent.content.imagen && <figure className='figure'>
                                        <ImageComponent base64ImageData={subContent.content.imagen} className='figure-img img-fluid img-cover rounded h-75' imgDefault={false} />
                                    </figure>}
                                </>)}
                        </div>
                        <figure className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                            <QuaPieChart data={content} onSubContent={setSubContent} position={posicion} />
                        </figure>
                    </div>
                );
            case 5:
                return (
                    <div className='row g-1'>
                        <figure className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                            <QuaPieChart data={content} onSubContent={setSubContent} position={posicion} />
                        </figure>
                        <div className="container col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 align-self-center p-2 ">
                            {Object.entries(subContent).length === 0 ?
                                (<>
                                    <h2 className="title-section text-center font-40">{content.titulo}</h2>
                                    <hr className="title-divider bg-primary m-auto" />
                                    <br />
                                </>) : (<>
                                    {subContent.content.titulo && <h2 className="text-section text-center font-18 fw-bold">{subContent.content.titulo}</h2>}
                                    {subContent.content.nombre && <h2 className="text-section text-center font-18 fw-bold">{subContent.content.nombre}</h2>}
                                    <hr className="title-divider bg-primary m-auto" />
                                    <p className='text-section text-center font-16' dangerouslySetInnerHTML={{ __html: subContent.content.descripcion }}></p>
                                    {subContent.content.imagen && <figure className='text-center'>
                                        <ImageComponent base64ImageData={subContent.content.imagen} className='figure-img img-fluid img-cover  rounded h-75' imgDefault={false} />
                                    </figure>}
                                </>)}
                        </div>
                    </div>
                );
            case 6:
                return (
                    <div className='row g-1'>
                        <div className="container col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 align-self-center p-2 ">
                            {Object.entries(subContent).length === 0 ?
                                (<>
                                    <h2 className="title-section text-center font-40">{content.titulo}</h2>
                                    <hr className="title-divider bg-primary m-auto" />
                                    <br />
                                </>) : (<>
                                    {subContent.content.titulo && <h2 className="text-section text-center font-18 fw-bold">{subContent.content.titulo}</h2>}
                                    {subContent.content.nombre && <h2 className="text-section text-center font-18 fw-bold">{subContent.content.nombre}</h2>}
                                    <hr className="title-divider bg-primary m-auto" />
                                    <p className='text-section text-center font-16' dangerouslySetInnerHTML={{ __html: subContent.content.descripcion }}></p>
                                    {subContent.content.imagen && <figure className='text-center'>
                                        <ImageComponent base64ImageData={subContent.content.imagen} className='figure-img img-fluid img-cover rounded h-75' imgDefault={false} />
                                    </figure>}
                                </>)}
                        </div>
                        <figure className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                            <QuaPieChart data={content} onSubContent={setSubContent} position={posicion} />
                        </figure>
                    </div>
                );
            default:
                return (
                    <div className='row g-1'>
                        <figure className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                            <SubPieChart data={content} onSubContent={setSubContent} />
                            {/* <QuaPieChart data={content} onSubContent={setSubContent} position={posicion} /> */}
                        </figure>
                        <div className="container col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 align-self-center order-first p-2 ">
                            {Object.entries(subContent).length === 0 ?
                                (<>
                                    <h2 className="title-section text-center font-40">{content.titulo}</h2>
                                    <hr className="title-divider bg-primary m-auto" />
                                    <br />
                                </>) : (<>
                                    {subContent.content.titulo && <h2 className="text-section font-18 fw-bold">{subContent.content.titulo}</h2>}
                                    {subContent.content.nombre && <h2 className="text-section font-18 fw-bold">{subContent.content.nombre}</h2>}
                                    <hr className="title-divider bg-primary" />
                                    <p className='text-section font-16' dangerouslySetInnerHTML={{ __html: subContent.content.descripcion }}></p>
                                    {subContent.content.imagen && <figure className='figure'>
                                        <ImageComponent base64ImageData={subContent.content.imagen} className='figure-img img-fluid img-cover rounded h-75' imgDefault={false} />
                                    </figure>}
                                </>)}
                        </div>
                    </div>
                );
        }
    }

    return (
        <>
            <div
                id={`descriptionModal-${id}`}
                className="modal fade"
                tabIndex="-1"
                aria-labelledby={`descriptionModalLabel-${id}`}
                aria-hidden="true"
            >
                {content.subContent ?
                    (
                        <div className="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h6 className="modal-title" id={`descriptionModalLabel-${id}`}>
                                        {content.titulo}
                                    </h6>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-mdb-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    {content.subContent && getPosition(posicion)}
                                    {content.subContent.list &&
                                        <div className='row g-1'>
                                            {content.img && (<figure className='col-xxl-3 col-xl-3 col-lg-12 col-md-12 col-sm-12 figure order-last'>
                                                <img src={content.img} className='figure-img img-fluid img-cover rounded shadow-3 h-100' alt={content.title} />
                                            </figure>)}
                                            <div className=" col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 p-2">
                                                <h2 className="title-section font-25">{content.titulo}</h2>
                                                <hr className="title-divider bg-primary" />
                                                <p className="text-section font-16">{content.description}</p>
                                            </div>
                                            <div className='col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-6 align-self-center p-2'>
                                                <ul className=" list-group list-group-light list-group-small list-group-numbered ">
                                                    {content.subContent.list.map((list, index) => (
                                                        <li key={index} className="list-group-item border-0 font-16">{list.item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                    {content.subContent.accordion &&
                                        <div className='row flex-row-reverse g-1'>
                                            {content.img && (<figure className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12 figure'>
                                                <img src={content.img} className='figure-img img-fluid img-cover rounded shadow-3 h-100' alt={content.title} />
                                            </figure>)}
                                            <div className="container col-xxl-9 col-xl-9 col-lg-8 col-md-8 col-sm-12 order-first align-self-center p-2 ">
                                                <h2 className="title-section font-25">{content.titulo}</h2>
                                                <hr className="title-divider bg-primary" />
                                                <p className="text-section font-16">{content.description}</p>
                                                <div className="accordion" id="accordionExample">
                                                    {content.subContent.accordion.map((accordion, index) => (
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id={`heading-${index}`}>
                                                                <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-mdb-toggle="collapse"
                                                                    data-mdb-target={`#collapse-${index}`}
                                                                    aria-expanded="false"
                                                                    aria-controls={`collapse-${index}`}
                                                                >
                                                                    {accordion.titulo}
                                                                </button>
                                                            </h2>
                                                            <div id={`collapse-${index}`} className="accordion-collapse collapse" aria-labelledby={`heading-${index}`} data-mdb-parent="#accordionExample">
                                                                <div className="accordion-body">
                                                                    <p className='font-16'>{accordion.description}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="modal-dialog modal-dialog-centered modal-lg modal-fullscreen-md-down">
                            <div className="modal-content text-center">
                                <div className="modal-header ">
                                    <h6 className="modal-title" id={`descriptionModalLabel-${id}`}>
                                        {content.titulo}
                                    </h6>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-mdb-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row flex-row-reverse g-1'>
                                        <div className="align-self-center order-first p-2">
                                            <h2 className="text-section font-18 fw-bold">{content.titulo}</h2>
                                            <hr className="title-divider bg-primary m-auto" />
                                            <div className="text-section font-16" dangerouslySetInnerHTML={{ __html: content.descripcion }}></div>
                                            <figure className='figure'>
                                                <ImageComponent base64ImageData={content.imagen} className='figure-img img-fluid img-cover rounded h-75' height={content.height} imgDefault={false} icon={content.icon} nameIcon={content.nameIcon} />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div >
        </>
    )
}
