import React from 'react';
import cloud from '../../Assets/img-content/cloud_procesing.png'

export const CloudProcesingSection = () => {
    return (
        <section className='container-fluid'>
            <br />
            <div className='container'>
                <h2 className='title-section text-center font-40'>Opción Renta "Cloud Procesing" (en la nube)</h2>
                <hr className='title-divider bg-primary m-auto my-3' />
                <p className='text-section text-center font-18'>Con opción SaaS la Institución no tiene que invertir en Servidores de Base de datos ni de Aplicaciones ni Servidor de Internet, tampoco tiene que adquirir licencias de Base de datos, Soft Warehouse proporciona todo el Hardware y Software, así como la Base de datos alojados en Amazon (AWS). La Institución solo contrata los enlaces de comunicaciones y paga un valor mínimo mensual de acuerdo al número de clientes/productos activos que tenga.</p>
            </div>
            <br />
            <div className='container text-center'>
                <img className='img-fluid'  src={cloud} alt="Cloud Procesing" loading='lazy' />
            </div>
        </section >
    )
}