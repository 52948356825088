import React, { useContext } from 'react';
import { LanguageContext } from '../../Context/LanguageContext';

export const OurValuesSection = () => {

	const { texto } = useContext(LanguageContext);

	return (<>
		<section id='ourvaluessction' className='container-fluid'>
			<br />
			<div className="container-md">
				<h2 className="title-section text-center font-40">{texto.seccion.nosotros.valores.titulo}</h2>
				<hr className="title-divider bg-primary m-auto mb-30" />
				<p className="text-section text-center font-18">{texto.seccion.nosotros.valores.parrafo1}</p>
			</div>
			<br />
			<div className="container">
				<div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-sm-2 row-cols-xs-1 gy-3 justify-content-center">
					<div className="col">
						<div className="card text-center border border-dark mb-1 h-100  ">
							<div className="card-body">
								<i className="bi bi-hand-thumbs-up font-40"></i>
								<h5 className="card-title font-18 ">{texto.seccion.nosotros.valores.confianza.titulo}</h5>
								<p className="text-section card-text font-14">{texto.seccion.nosotros.valores.confianza.descripcion}</p>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card text-center border border-dark mb-1 h-100">
							<div className="card-body">
								<i className="bi bi-person-check font-40"></i>
								<h5 className="card-title font-18">{texto.seccion.nosotros.valores.creabilidad.titulo}</h5>
								<p className="text-section card-text font-14">{texto.seccion.nosotros.valores.creabilidad.descripcion}</p>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card text-center border border-dark mb-1 h-100">
							<div className="card-body">
								<i className="bi bi-award font-40"></i>
								<h5 className="card-title font-18">{texto.seccion.nosotros.valores.etica.titulo}</h5>
								<p className="text-section card-text font-14">{texto.seccion.nosotros.valores.etica.descripcion}</p>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card text-center border border-dark mb-1 h-100">
							<div className="card-body">
								<i className="bi bi-code-slash font-40"></i>
								<h5 className="card-title font-18">{texto.seccion.nosotros.valores.compromiso.titulo}</h5>
								<p className="text-section card-text font-14">{texto.seccion.nosotros.valores.compromiso.descripcion}</p>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card text-center border border-dark mb-1 h-100">
							<div className="card-body">
								<i className="bi bi-people-fill font-40"></i>
								<h5 className="card-title font-18">{texto.seccion.nosotros.valores.disponibilidad.titulo}</h5>
								<p className="text-section card-text font-14">{texto.seccion.nosotros.valores.disponibilidad.descripcion}</p>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card text-center border border-dark mb-3 h-100">
							<div className="card-body">
								<i className="bi bi-shield-shaded font-40"></i>
								<h5 className="card-title font-18">{texto.seccion.nosotros.valores.seguridad.titulo}</h5>
								<p className="text-section card-text font-14">{texto.seccion.nosotros.valores.seguridad.descripcion}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<br />
		</section>
	</>)
}