import React from 'react';
import ImageComponent from '../../../Components/tools/Content/ImageComponent';
import { Link } from 'react-router-dom';

export const TechnologiesSection = ({ data = {} }) => {

    return (<>
        <section className="container-fluid text-center">
            <div className="container-md">
                <h2 className="title-section">Tecnologías </h2>
                <hr className="title-divider bg-primary m-auto mb-30" />
                <p className="text-section  font-16">¡Explora nuestras innovadoras tecnologías para el futuro de la banca!</p>
            </div>
            <div className="container-fluid">
                <div className="row row-cols-xxl-3 row-cols-xl-3  row-cols-lg-2  row-cols-md-2 row-cols-sm-1 row-cols-xs-1 row-cols-1 g-3 justify-content-center">
                    {data?.map((item, index) => (
                        <div key={index} className='col'>
                            <div className="card p-0 mh-100 mw-100" >
                                <div className="row g-0">
                                    <div className="col-4 ">
                                        <ImageComponent base64ImageData={item.imagen} className="img-fluid rounded-start img-cover h-100" alt={`Image-${item.titulo}`} />
                                    </div>
                                    <div className="col-8 align-self-center">
                                        <div className="card-body">
                                            <h5 className="card-title">{item.titulo}</h5>
                                            <p className="card-text font-14" dangerouslySetInnerHTML={{ __html: item.descripcioncorta.slice(0, 150)}}></p>
                                            <Link to={`/tecnologia/${item.id}`} className="btn btn-primary btn-sm">Saber mas</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    </>)
}