import React, { useContext } from 'react';
import mision_img from '../../Assets/img/pexels-photo-5256816.jpeg';
import vision_img from '../../Assets/img/pexels-photo-5673488.webp';
import { LanguageContext } from '../../Context/LanguageContext';

export const MissionAndVisionSection = () => {

	const { texto } = useContext(LanguageContext);

	return (
		<section id='mision-vision-section' className='container-fluid'>
			<div className='row g-1'>
				<div className="col-xxl-7 col-xl-7 col-lg-7 col-md-6 col-sm-12 align-self-center  p-3">
					<h2 className="title-section font-40">{texto.seccion.nosotros.mision.titulo}</h2>
					<hr className="title-divider bg-primary" />
					<p className="text-section">{texto.seccion.nosotros.mision.parrafo1}</p>
				</div>
				<figure className='col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-12 p-2'>
					<img src={mision_img} className='figure-img img-fluid img-cover rounded shadow-3 h-100' alt="Mission" loading="lazy" />
				</figure>
			</div>
			<br />
			<br />
			<div className='row flex-row-reverse g-1'>
				<figure className='col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-12 p-2'>
					<img src={vision_img} className='figure-img img-fluid img-cover rounded shadow-3 h-100' alt="Vision" loading="lazy" />
				</figure>
				<div className="col-xxl-7 col-xl-7 col-lg-7 col-md-6 col-sm-12 align-self-center order-first p-3 ">
					<h2 className="title-section font-40">{texto.seccion.nosotros.vision.titulo}</h2>
					<hr className="title-divider bg-primary" />
					<p className="text-section">{texto.seccion.nosotros.vision.parrafo1}</p>
				</div>
			</div>
		</section>
	)
}