import React, { useState } from 'react';
import { ViewStructure } from './ViewStructure';
import useFetch from '../../Hooks/useFetch';
import Spinner from '../../Components/Layout/Spinner';

export const StructureSection = () => {

    const [data, setData] = useState();
    const { loading } = useFetch(`subdiagram/seps`, setData);

    return (<>
        <section className='container-fluid'>
            {data && <>
                <ul className="nav nav-tabs nav-justified mb-3 p-3" id="tab" role="tablist">
                    {data.SubDiagramas?.map((item, index) => (
                        <li key={index} className="nav-item" role="presentation">
                            <a
                                className={`nav-link ${index === 0 ? 'active' : ''}`}
                                id={`tab-${index}`}
                                data-mdb-toggle="tab"
                                href={`#tabs-${index}`}
                                role="tab"
                                aria-controls={`tabs-${index}`}
                                aria-selected={index === 0 ? "true" : "false"}
                            >{item.titulo}</a>
                        </li>
                    ))}
                </ul>
                <div className="tab-content" id="tab-content">
                    {data.SubDiagramas?.map((item, index) => (
                        <div
                            key={`tabs-${index}`}
                            className={`tab-pane fade ${index === 0 ? 'show active': ''}`}
                            id={`tabs-${index}`}
                            role="tabpanel"
                            aria-labelledby={`tab-${index}`}
                        >
                            <ViewStructure content={item} index={index} />
                        </div>
                    ))}
                </div>
            </>
            }
            {loading && <Spinner />}
        </section>
    </>)
}