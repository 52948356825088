import React, { useContext } from 'react';
import { ContentComponent } from '../../../Components/tools/Content/ContentComponent';
import { LanguageContext } from '../../../Context/LanguageContext';

export const StructureSection = ({ name, type, structures }) => {

	const { texto } = useContext(LanguageContext);

	return (
		<section className='container-fluid'>
			<div className='container-md'>
				<h2 className='title-section text-center font-40'>{texto.seccion.productos.estructura.titulo}</h2>
				<hr className="title-divider bg-primary m-auto mb-30" />
				<p className="text-section text-center font-16"><b>{name}</b>{texto.seccion.productos.estructura.parrafo1}</p>
			</div>
			<ContentComponent type={type} content={structures} />
		</section>
	)
}