import React from 'react';

import LE from '../../Assets/img-content/licencia_instalacion.png'
export const LicensesAndInstallationSection = () => {
    return (
        <section className='container-fluid'>
            <br />
            <div className='container'>
                <h2 className='title-section text-center font-40'>Opción Venta de Vicencias e Instalación en la Institución (on premise)</h2>
                <hr className='title-divider bg-primary m-auto my-3' />
                <p className='text-section text-center font-18'>En esta opción la Institución debe adquirir los servidores en donde se instalará el sistema, debe también adquirir la licencia de la base de datos y del software ambiental y contratar los enlaces de comunicaciones entre la Matriz y las Sucursales, también debe adquirir la licencia del sistema FIT-BANK, cancelar el valor de la implantación y pagar el mantenimiento mensual, además debe contar con personal técnico para operar los equipos y el sistema.</p>
            </div>
            <br />
            <div className='container'>
                <img className='img-fluid' src={LE} alt="Licencia e Instalacion" loading='lazy' />
            </div>
        </section>
    )
}