import React from "react";
import { Routes, Route } from "react-router-dom";
import { ListDiagramas } from "../Pages/Diagrams/ListDiagramas";
import { NavBar } from "../Pages/App/NavBar";
import { Home } from "../Pages/Home/Home";
import { Pruebas } from "../Pages/test/Pruebas";
import { ShowProduct } from "../Pages/Product/Show/ShowProduct";
import { UsPage } from "../Pages/Us/UsPage";
import { ListProduct } from "../Pages/Product/List/ListProduct";
import { ShowDiagrama } from "../Pages/Diagrams/ShowDiagrama";
import { ListSubDiagrama } from "../Pages/Diagrams/ListSubDiagrama";
import { ShowSubDiagrama } from "../Pages/Diagrams/ShowSubDiagrama";
import { Page404, Page500 } from "../Pages/Errors";
import { ListModules } from "../Pages/Modules/List/ListModules";
import { ShowModule } from "../Pages/Modules/Show/ShowModule";
import { ListTechnologies } from "../Pages/Technologies/List/ListTechnologies";
import { ShowTechnologie } from "../Pages/Technologies/Show/ShowTechnologie";
import { ListServices } from "../Pages/Services/List/ListServices";
import { ShowService } from "../Pages/Services/Show/ShowService";
import { SpesPage } from "../Pages/SEPS/SepsPage";
import { ClientPage } from "../Pages/Clients/ClientPage";
import { DeployRequestDemo } from "../Pages/DeployRequest/DeployRequestDemo";


export const AppRouter = () => {
    return (
        <Routes>
            <Route
                path="/*"
                element={
                    <Routes>
                        <Route element={<NavBar />} >
                            <Route index pach="/" element={<Home />}></Route>
                            <Route path="pruebas" element={<Pruebas />} />

                            <Route path="diagramas" >
                                <Route index element={<ListDiagramas />} />
                                <Route path=':id' element={<ShowDiagrama />} />
                            </Route>

                            <Route path="subdiagramas" >
                                <Route index element={<ListSubDiagrama />} />
                                <Route path=':id' element={<ShowSubDiagrama />} />
                            </Route>

                            <Route path='producto'>
                                <Route index element={<ListProduct />} />
                                <Route path=':id/:name' element={<ShowProduct />} />
                            </Route>

                            <Route path='modulo'>
                                <Route index element={<ListModules />} />
                                <Route path=':id' element={<ShowModule />} />
                            </Route>

                            <Route path='tecnologia'>
                                <Route index element={<ListTechnologies />} />
                                <Route path=':id' element={<ShowTechnologie />} />
                            </Route>

                            <Route path='servicio'>
                                <Route index element={<ListServices />} />
                                <Route path=':id' element={<ShowService />} />
                            </Route>

                            <Route path='estructuras'>
                                <Route index element={<SpesPage />} />
                            </Route>

                            <Route path="nosotros" element={<UsPage />} />
                            <Route path="clientes" element={<ClientPage />} />

                            <Route path="demo" element={<DeployRequestDemo/>} />

                            <Route path="*" element={<Page404 />} />

                            <Route path="404" element={<Page404 />} />

                            <Route path="500" element={<Page500 />} />

                        </Route>
                    </Routes>
                }
            />
        </Routes>
    );
};
