import React, { useContext, useState } from 'react';
import { PAISES_AMERICANOS } from '../../Data/Constantes';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import axios from 'axios';
import { Toaster, toast } from 'sonner';
import { LanguageContext } from '../../Context/LanguageContext';

export const ContactUsSection = () => {

	const { texto } = useContext(LanguageContext);

	const apiUrl = process.env.REACT_APP_BACKEND_URL;

	const [message, setMessage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [nombre, setNombre] = useState('');
	const [institucion, setInstitucion] = useState('');
	const [cargo, setCargo] = useState('');
	const [correo, setCorreo] = useState('');
	const [codigoarea, setCodigoarea] = useState();
	const [telefono, setTelefono] = useState('');
	const [mensaje, setMensaje] = useState('');

	const telInputProps = {
		placeholder: 'Ingresa tu número de teléfono',
		name: 'phoneNumber',
		required: true,
		pattern: '[0-9]*', // Patrón de solo números
		minLength: '5',
		maxLength: '12'
	};

	const clearValues = () => {
		setNombre('');
		setInstitucion('');
		setCargo('');
		setCorreo('');
		setTelefono('');
		setMensaje('');
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const res = await axios.post(
				`${apiUrl}/contactus`,
				{ nombre, institucion, cargo, correo, codigoarea, telefono, mensaje },
				{ headers: { 'accept': 'application/json' } }
			)
			res.data.message && setMessage(res.data.message);
			toast.success(res.data.message);
			clearValues();
		} catch (err) {
			setError('Un error a ocurrido');
			console.error(err);
			if (err.response.status === 422) {
				toast.error(err.response.data.message);
			} else {
				toast.error('Un error ha ocurrido, inténtalo mas tarde.');
			}
		} finally {
			setLoading(false);
			console.log(message + "\n" + loading + "\n" + error);
		}
	}

	return (<>
		<Toaster richColors position="bottom-center" expand={true} />
		<section id='contactus' className='container-fluid'>
			<div className='row'>
				<div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 align-self-center px-5'>
					<h2 className="title-section text-center font-40">{texto.inicio.contactenos.titulo}</h2>
					<hr className="title-divider bg-primary m-auto" />
					<p className="text-section text-center font-14">{texto.inicio.contactenos.parrafo1}</p>
					<form className='font-14' onSubmit={handleSubmit} >
						<div className="form-outline mb-3 ">
							<input
								type="text"
								id="nombre"
								autoComplete="off"
								name='nombre'
								minLength="3"
								maxLength="50"
								value={nombre}
								className="form-control"
								onChange={(e) => setNombre(e.target.value)}
								required />
							<label className="form-label" htmlFor="nombre">{texto.inicio.contactenos.form.nombre}</label>
							<div className="form-notch">
								<div className="form-notch-leading" ></div>
								<div className="form-notch-middle" ></div>
								<div className="form-notch-trailing"></div>
							</div>
						</div>
						<div className="form-outline mb-3">
							<input
								type="text"
								id="institucion"
								autoComplete="off"
								name='institucion'
								minLength="3"
								maxLength="50"
								value={institucion}
								className="form-control"
								onChange={(e) => setInstitucion(e.target.value)}
								required />
							<label className="form-label" htmlFor="institucion">{texto.inicio.contactenos.form.pertenece}</label>
							<div className="form-notch">
								<div className="form-notch-leading" ></div>
								<div className="form-notch-middle" ></div>
								<div className="form-notch-trailing"></div>
							</div>
						</div>
						<div className="form-outline mb-3">
							<input
								type="text"
								id="cargo"
								autoComplete="off"
								name='cargo'
								minLength="3"
								maxLength="50"
								value={cargo}
								className="form-control"
								onChange={(e) => setCargo(e.target.value)}
								required />
							<label className="form-label" htmlFor="cargo">{texto.inicio.contactenos.form.cargo}</label>
							<div className="form-notch">
								<div className="form-notch-leading" ></div>
								<div className="form-notch-middle" ></div>
								<div className="form-notch-trailing"></div>
							</div>
						</div>
						<div className="form-outline mb-3">
							<input
								type="email"
								id="correo"
								autoComplete="on"
								name='correo'
								minLength="3"
								maxLength="50"
								value={correo}
								className="form-control"
								onChange={(e) => setCorreo(e.target.value)}
								required />
							<label className="form-label" htmlFor="correo">{texto.inicio.contactenos.form.correo}</label>
							<div className="form-notch">
								<div className="form-notch-leading" ></div>
								<div className="form-notch-middle" ></div>
								<div className="form-notch-trailing"></div>
							</div>
						</div>
						<div className="d-grid mb-3">
							<IntlTelInput
								inputClassName='form-control'
								value={telefono}
								onPhoneNumberChange={(status, phoneNumber, countryData, formattedPhoneNumber, isValid) => setTelefono(phoneNumber)}
								onPhoneNumberBlur={(status, phoneNumber, countryData, formattedPhoneNumber, isValid) => setCodigoarea(countryData.dialCode)}
								preferredCountries={['EC']}
								onlyCountries={PAISES_AMERICANOS}
								telInputProps={telInputProps} />
						</div>
						<div className="form-outline mb-3">
							<textarea
								className="form-control"
								id="mensaje"
								autoComplete='off'
								name='mensaje'
								maxLength="150"
								value={mensaje}
								onChange={(e) => setMensaje(e.target.value)}
								rows="3"></textarea>
							<label className="form-label" htmlFor="mensaje">{texto.inicio.contactenos.form.mensaje}</label>
							<div className="form-notch">
								<div className="form-notch-leading" ></div>
								<div className="form-notch-middle" ></div>
								<div className="form-notch-trailing"></div>
							</div>
						</div>
						{loading === true ?
							<button type="submit" className="btn btn-primary btn-block mb-3 disabled">{texto.acciones.enviado}</button> :
							<button type="submit" className="btn btn-primary btn-block mb-3">{texto.acciones.enviar}</button>}
					</form>
				</div>

				<div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-4 bg-dark'>
					<h2 className="title-section text-white font-25">{texto.inicio.contactenos.info.titulo}</h2>
					<hr className="title-divider bg-white" />
					<div className='d-flex align-items-center'>
						<i className="bi bi-envelope-at text-white font-25 p-2"></i>
						<span className='text-white font-14 p-2'>
							<strong>{texto.inicio.contactenos.info.correo}</strong><br />
							<a className='text-white' id='hover-item' href='mailto:info@softwarehouse.com.ec'>info@softwarehouse.com.ec</a>
						</span>
					</div>
					<div className='d-flex align-items-center'>
						<i className="bi bi-telephone text-white font-25 p-2"></i>
						<span className='text-white font-14 p-2'>
							<strong>{texto.inicio.contactenos.info.telefonos}</strong><br />
							<a className='text-white' id='hover-item' href='tel:+593984538500' >+593 98 453 8500</a> / <a className='text-white' id='hover-item' href='tel:+593999744957' >+593 99 974 4957</a>
						</span>
					</div>
					<div className='d-flex align-items-center'>
						<i className="bi bi-house text-white font-25 p-2"></i>
						<a className='text-white font-14 p-2' id='hover-item' href='https://maps.app.goo.gl/MuQbciYMMvwosU119' target='blank'><strong>{texto.inicio.contactenos.info.ofi_matriz}</strong><br />Nela Martínez s/n intersección Camilo Ponce Enríquez acceso Autopista General Rumiñahui, Puente 3 <br />Conocoto – Quito - Ecuador</a>
					</div>
					<div className='d-flex align-items-center'>
						<i className="bi bi-building text-white font-25 p-2"></i>
						<a className='text-white font-14 p-2' id='hover-item' href='https://maps.app.goo.gl/USqyDMRKhi6zVqU26' target='blank'><strong>{texto.inicio.contactenos.info.ofi_cuenca}</strong><br />Av. Fray Vicente Solano y Remigio Crespo Edificio Colegio de Ingenieros Civiles del Azuay (CICA) <br />Oficina 402</a>
					</div>
					<div className='d-flex align-items-center'>
						<i className="bi bi-clock text-white font-25 p-2"></i>
						<p className='text-white font-14 p-2'><strong>{texto.inicio.contactenos.info.horario}</strong><br />Mon to Fri: 09:00 a.m. – 06:00 p.m.</p>
					</div>
					<div className="d-flex justify-content-end gap-3">
						<a className="btn text-white bg-success btn-rounded" href="https://wa.me/593984266250" target='blank' role="button">
							<i className="bi bi-whatsapp font-14 me-2"></i>
							Whatsapp
						</a>
						<a className="btn text-white bg-primary btn-rounded" href="https://www.linkedin.com/company/soft-warehouse-s.a./" target='blank' role="button">
							<i className="bi bi-linkedin font-14 me-2"></i>
							Linkedin
						</a>
					</div>
				</div>
			</div>
		</section>
	</>)
}