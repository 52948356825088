import React, { useContext } from 'react';
import { LanguageContext } from '../../Context/LanguageContext';

export const LanguageSelector = () => {
	const { language, setLanguage, texto } = useContext(LanguageContext);

	const handleLanguageChange = (newLanguage) => {
		setLanguage(newLanguage);
	};

	const handleSelectChange = (event) => {
		const selectedValue = event.target.value;
		if (selectedValue === 'ES') {
			setLanguage('ES');
		} else if (selectedValue === 'EN') {
			setLanguage('EN');
		}
	};

	return (
		<div >
			<select className="form-select" aria-label=".form-select-md" value={language === 'ES' ? 'ES' : 'EN'} onChange={handleSelectChange}>
				<option value="ES">{texto.lang.es}</option>
				<option value="EN">{texto.lang.en}</option>
			</select>
			{/* <button onClick={(e) => handleLanguageChange('ES')}>ES</button>
            <button onClick={(e) => handleLanguageChange('EN')}>EN</button> */}
		</div>
	);
};

