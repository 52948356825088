import React, { useContext, useState } from 'react'
//import General from '../../Data/General_product.json';
import General from '../../Data/General_fit.json';
import fitbank_img from '../../Assets/logo_fitbank_nuevo transparente.png'
import PieChart from '../../Components/Layout/PieChart';
import useFetch from '../../Hooks/useFetch';
import { LanguageContext } from '../../Context/LanguageContext';
import { ContentComponent } from '../../Components/tools/Content/ContentComponent';

export const ModulosSection = () => {

	const { texto } = useContext(LanguageContext);
	const [data, setData] = useState();
	const { loading, error } = useFetch(`diagram/5`, setData, true);

	return (
		<>
			<section id="moduleSection" className='container-fluid justify-content-center'>
				<br />
				<div className='row flex-row-reverse g-1'>
					<figure className='col-xxl-5 col-xl-6 col-lg-12 col-md-12 col-sm-12 align-self-center'>
						<div className='text-center'>
							<img className="" height="100" src={fitbank_img} alt="Fit-Bank" />
						</div>
						{data && <PieChart data={data.Diagrama} />}
						{(loading || error) && <PieChart data={General} />}
					</figure>
					<div className="container col-xxl-7 col-xl-6 col-lg-12 col-md-12 col-sm-12 align-self-center order-first p-2 ">
						<h2 className="title-section text-white font-40">{texto.inicio.modulos.titulo}</h2>
						<hr className="title-divider bg-light" />
						<p className="text-section text-white font-18" dangerouslySetInnerHTML={{ __html: texto.inicio.modulos.parrafo1 }}></p>
						<p className="text-section text-white font-18">{texto.inicio.modulos.parrafo2}</p>
						<p className="text-section text-white font-18">{texto.inicio.modulos.parrafo3}</p>
						<p className="text-section text-white font-18">{texto.inicio.modulos.parrafo4}</p>
					</div>
				</div>
			</section>
			<br />
			<br />
			<section className='container-fluid'>
			<div className='container-md'>
				<h2 className='title-section text-center font-35'>{texto.seccion.modulos.titulo}</h2>
				<hr className="title-divider bg-primary m-auto mb-30" />
				<p className="text-section text-center font-18">{texto.seccion.modulos.parrafo1}</p>
			</div>
			<ContentComponent type='002' content={texto.seccion.modulos.structures} />
		</section>
		</>
	)
}