import React from 'react';
import {CONTENT} from '../../../Data/Constantes';

export const ContentComponent = ({ type, content = {} }) => {
    
    switch (type) {
        case CONTENT.LIST:
            return (
                <div className='container'>
                    <ol className="list-group list-group-light">
                        {content?.map((item, index) => (
                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                <div>
                                    {item.titulo && <div className="fw-bold">{item.titulo}</div>}
                                    <div className="text-muted" dangerouslySetInnerHTML={{ __html: item.descripcion }}></div>
                                </div>
                            </li>
                        ))}
                    </ol>
                </div>
            )

        case CONTENT.ACCORDION: 
            return (
                <div className='container'>
                    <div className="accordion" id="accordionFlush">
                        {content?.map((item, index) => (
                            <div key={index}  className="accordion-item">
                            <h2 className="accordion-header" id={`flush-heading-${index}`}>
                                <button
                                    className={`accordion-button ${index === 0? '': 'collapsed'}`}
                                    type="button"
                                    data-mdb-toggle="collapse"
                                    data-mdb-target={`#flush-collapse-${index}`}
                                    aria-expanded={index === 0? 'true' : 'false'}
                                    aria-controls={`flush-collapse-${index}`}
                                >
                                    <b>{item.titulo != null ? item.titulo : `Item #${index + 1}`}</b>
                                </button>
                            </h2>
                            <div
                                id={`flush-collapse-${index}`}
                                className={`accordion-collapse collapse ${index === 0 ?'show' : '' }`}
                                aria-labelledby={`flush-heading-${index}`}
                                data-mdb-parent="#accordionFlush"
                            >
                                <div className="accordion-body" dangerouslySetInnerHTML={{ __html: item.descripcion }}></div>
                            </div>
                        </div>

                        ))}
                    </div>
                </div>
            )

        default: // List
            return (
                <div className='container'>
                    <ol className="list-group list-group-light">
                        {content?.map((item, index) => (
                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                <div>
                                    {item.titulo && <div className="fw-bold">{item.titulo}</div>}
                                    <div className="text-muted" dangerouslySetInnerHTML={{ __html: item.descripcion }}></div>
                                </div>
                            </li>
                        ))}
                    </ol>
                </div>
            )
    }
}