import React, { useContext, useEffect, useRef, useState } from 'react';

import clients from '../../Data/Clients.json';
import ImageComponent from '../../Components/tools/Content/ImageComponent';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../Context/LanguageContext';


export const ClientSection = () => {
	
	const { texto } = useContext(LanguageContext);
	const [items, setItems] = useState([]);
	const carousel = useRef(null);

	const responsive = {
		0: { items: 1 },
		568: { items: 2 },
		1024: { items: 3 },
	};

	const renderDotsItem = ({ isActive }) => {
		return isActive ? <i className="bi bi-circle-fill text-primary px-1 font-9"></i> : <i className="bi bi-circle-fill text-secondary px-1 font-9"></i>;
	}

	useEffect(() => {
		const fetchClientsData = async () => {
			try {
				const imageItems = clients.map((client, index) => (
					<>
						<ImageComponent
							key={index}
							base64ImageData={client.imagen}
							className="d-block w-100 img-cover"
							alt={client.siglas}
						/>
						<p className='text-center font-16 mt-2'>{client.nombre}</p>
					</>
				));
				setItems(imageItems);
			} catch (error) {
				console.error('Error al cargar los datos:', error);
			}
		};
		fetchClientsData();
	}, []);

	return (
		<section className='container-fluid text-center'>
			<div className="container-md">
				<h2 className="title-section font-40">{texto.inicio.clientes.titulo}</h2>
				<hr className="title-divider bg-primary m-auto mb-30" />
				<p className="text-section font-18">{texto.inicio.clientes.parrafo1}</p>
			</div>
			<br />
			<div className='container-fluid justify-content-center align-self-center'>
				<div className='row'>
					<div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-6 col-6 order-md-0 order-sm-1 order-1 p-0 text-center align-self-center'>
						<button type="button" className="btn btn-primary btn-md	 btn-floating" onClick={(e) => carousel?.current?.slidePrev(e)}>
							<i className="bi bi-caret-left-fill font-18" onClick={(e) => carousel?.current?.slidePrev(e)}></i>
						</button>
					</div>
					<div className='container p-0 col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 order-md-1 order-sm-0 order-0 '>
						<AliceCarousel
							autoPlay
							autoPlayStrategy="default"
							autoPlayInterval={1000}
							animationDuration={1500}
							animationType="slide"
							infinite
							items={items}
							mouseTracking
							disableButtonsControls
							responsive={responsive}
							controlsStrategy="alternate"
							renderDotsItem={renderDotsItem}
							ref={carousel}
						/>
					</div>
					<div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-6 col-6 order-md-2 order-sm-2 order-2 text-center align-self-center'>
						<button type="button" className="btn btn-primary btn-md btn-floating" onClick={(e) => carousel?.current?.slideNext(e)}>
							<i className="bi bi-caret-right-fill font-18" onClick={(e) => carousel?.current?.slideNext(e)}></i>
						</button>
					</div>
				</div>
				<br />
				<Link to='/clientes' className="btn btn-primary ">{texto.acciones.saber_mas}</Link>
			</div>
		</section>
	)
}