import React from "react";
import default_img from '../../../Assets/img/default.webp'
const ImageComponent = ({ base64ImageData, className = '', alt = '', height = '', imgDefault = true, icon}) => {

    if (base64ImageData) {
        return <img className={className} height={height} src={`data:${base64ImageData.nombrearchivo};base64,${base64ImageData.imagen}`} alt={alt} />
    } else if (imgDefault) {
        return <img className={className} height={height} src={default_img} alt={alt} />
    } else if (icon) {
        return <i className={`bi bi-${icon} ${height}`}></i>
    } else {
        return <></>
    }
};

export default ImageComponent;