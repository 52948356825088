import React, { useContext } from 'react';
import { LanguageContext } from '../../Context/LanguageContext';

export const ModuleTable = () => {

	const { texto } = useContext(LanguageContext);

	return (
		<section id='our-history-section' className='container-fluid'>
			<div className='container-md text-center p-3'>
				<h2 className='title-section font-40'>{texto.seccion.modulos.tabla.titulo}</h2>
				<hr className="title-divider bg-primary m-auto mb-30" />
			</div>
			<div className='container-fluid text-center table-responsive'>
				<table className="table table-sm text-center table-bordered border-dark align-middle table-hover ">
					<thead className="table-dark">
						<tr>
							<th>{texto.seccion.modulos.tabla.general.titulo}</th>
							<th>{texto.seccion.modulos.tabla.activo.titulo}</th>
							<th>{texto.seccion.modulos.tabla.pasivo.titulo}</th>
							<th>{texto.seccion.modulos.tabla.admin.titulo}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th>{texto.seccion.modulos.tabla.general.canales}</th>
							<td>{texto.seccion.modulos.tabla.activo.cuentas}</td>
							<td>{texto.seccion.modulos.tabla.pasivo.cuentas}</td>
							<td>{texto.seccion.modulos.tabla.admin.activos}</td>
						</tr>
						<tr>
							<th>{texto.seccion.modulos.tabla.general.admin}</th>
							<td>{texto.seccion.modulos.tabla.activo.estados}</td>
							<td>{texto.seccion.modulos.tabla.pasivo.chequera}</td>
							<td>{texto.seccion.modulos.tabla.admin.bienes}</td>
						</tr>
						<tr>
							<th>{texto.seccion.modulos.tabla.general.productos}</th>
							<td>{texto.seccion.modulos.tabla.activo.inversiones_plazo}</td>
							<td>{texto.seccion.modulos.tabla.pasivo.sobregiros}</td>
							<td>{texto.seccion.modulos.tabla.admin.suministros}</td>
						</tr>
						<tr>
							<td>{texto.seccion.modulos.tabla.general.tasas}</td>
							<td>{texto.seccion.modulos.tabla.activo.inversiones_fija}</td>
							<td>{texto.seccion.modulos.tabla.pasivo.cuentas_ahorro}</td>
							<td>{texto.seccion.modulos.tabla.admin.compras}</td>
						</tr>
						<tr>
							<td>{texto.seccion.modulos.tabla.general.facturacion}</td>
							<td>{texto.seccion.modulos.tabla.activo.inversiones_variable}</td>
							<td>{texto.seccion.modulos.tabla.pasivo.cuentas_ahorro_pro}</td>
							<td>{texto.seccion.modulos.tabla.admin.nomina}l</td>
						</tr>
						<tr>
							<td>{texto.seccion.modulos.tabla.general.gestor}</td>
							<td>{texto.seccion.modulos.tabla.activo.prestamo_pre}</td>
							<td>{texto.seccion.modulos.tabla.pasivo.depositos}</td>
							<td>{texto.seccion.modulos.tabla.admin.gastos}</td>
						</tr>
						<tr>
							<td>{texto.seccion.modulos.tabla.general.seguridad}</td>
							<td>{texto.seccion.modulos.tabla.activo.prestamo_anticipo}</td>
							<td>{texto.seccion.modulos.tabla.pasivo.obligaciones}</td>
							<td>{texto.seccion.modulos.tabla.admin.factura}</td>
						</tr>
						<tr>
							<td>{texto.seccion.modulos.tabla.general.personas}</td>
							<td>{texto.seccion.modulos.tabla.activo.prestamo_linea}</td>
							<td>{texto.seccion.modulos.tabla.pasivo.cuentas_pagar}</td>
							<td className='table-dark'>{texto.seccion.modulos.tabla.movil.titulo}</td>
						</tr>
						<tr>
							<td>{texto.seccion.modulos.tabla.general.contabilidad}</td>
							<td>{texto.seccion.modulos.tabla.activo.compra}</td>
							<td>{texto.seccion.modulos.tabla.pasivo.tarjeta_debito}</td>
							<td>{texto.seccion.modulos.tabla.movil.oficina}</td>
						</tr>
						<tr>
							<td className='table-dark'>{texto.seccion.modulos.tabla.cumplimiento.titulo}</td>
							<td>{texto.seccion.modulos.tabla.activo.credito}</td>
							<td>{texto.seccion.modulos.tabla.pasivo.tarjeta_credito}</td>
							<td>{texto.seccion.modulos.tabla.movil.banca_linea}</td>
						</tr>
						<tr>
							<td>{texto.seccion.modulos.tabla.cumplimiento.control}</td>
							<td>{texto.seccion.modulos.tabla.activo.garantias}</td>
							<td>{texto.seccion.modulos.tabla.pasivo.atm}</td>
							<td>{texto.seccion.modulos.tabla.movil.banca_movil}</td>
						</tr>
						<tr>
							<td>{texto.seccion.modulos.tabla.cumplimiento.judicial}</td>
							<td>{texto.seccion.modulos.tabla.activo.garantias}</td>
							<td>{texto.seccion.modulos.tabla.pasivo.conafips}</td>
							<td>{texto.seccion.modulos.tabla.movil.tienda}</td>
						</tr>
						<tr>
							<td>{texto.seccion.modulos.tabla.cumplimiento.reportes}</td>
							<td>{texto.seccion.modulos.tabla.activo.cobranza}</td>
							<td className='table-dark'>{texto.seccion.modulos.tabla.servicio.titulo}</td>
							<td>{texto.seccion.modulos.tabla.movil.billetera}</td>
						</tr>
						<tr>
							<td className='table-dark'>{texto.seccion.modulos.tabla.gerencia.titulo}</td>
							<td>{texto.seccion.modulos.tabla.activo.cartera_titulo}</td>
							<td>{texto.seccion.modulos.tabla.servicio.ventanilla}</td>
							<td>{texto.seccion.modulos.tabla.movil.solicitud}</td>
						</tr>
						<tr>
							<td>{texto.seccion.modulos.tabla.gerencia.informacion}</td>
							<td>{texto.seccion.modulos.tabla.activo.venta_cartera}</td>
							<td>{texto.seccion.modulos.tabla.servicio.spi}</td>
							<td>{texto.seccion.modulos.tabla.movil.corresponsal}</td>
						</tr>
						<tr>
							<td>{texto.seccion.modulos.tabla.gerencia.crm}</td>
							<td>{texto.seccion.modulos.tabla.activo.cartera_vendida}</td>
							<td>{texto.seccion.modulos.tabla.servicio.sci}</td>
							<td className='table-dark'>{texto.seccion.modulos.tabla.soporte.titulo}</td>
						</tr>
						<tr>
							<td>{texto.seccion.modulos.tabla.gerencia.presupuesto}</td>
							<td>{texto.seccion.modulos.tabla.activo.compra_cartera}</td>
							<td>{texto.seccion.modulos.tabla.servicio.compania}</td>
							<td>{texto.seccion.modulos.tabla.soporte.proceso}</td>
						</tr>
						<tr>
							<td></td>
							<td>{texto.seccion.modulos.tabla.activo.cuentas_cobrar}</td>
							<td>{texto.seccion.modulos.tabla.servicio.buro}</td>
							<td>{texto.seccion.modulos.tabla.soporte.recopilacion}</td>
						</tr>
						<tr>
							<td></td>
							<td className='table-dark'>{texto.seccion.modulos.tabla.contingencia.titulo}</td>
							<td>{texto.seccion.modulos.tabla.servicio.banca}</td>
							<td>{texto.seccion.modulos.tabla.soporte.anonimizacion}</td>
						</tr>
						<tr>
							<td></td>
							<td>{texto.seccion.modulos.tabla.contingencia.garantias}</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td></td>
							<td>{texto.seccion.modulos.tabla.contingencia.comercio}</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td></td>
							<td>{texto.seccion.modulos.tabla.contingencia.credito}</td>
							<td></td>
							<td></td>
						</tr>

					</tbody>
				</table>
			</div>
		</section>
	)
}