import React, { useState, useEffect } from "react";
import styled from "styled-components";

const SubPieChart = ({ data, onSubContent }) => {
    var indexRes = useWindowsSize().index;
    const radius = 245 / indexRes;
    const porcentaje = 100 / data.subContent.length;

    const [selectedItem, setSelectedItem] = useState(null);

    const handleMouseEnter = (index) => {
        setSelectedItem(index);
    };

    const handleMouseLeave = () => {
        setSelectedItem(null);
    };

    const [selectedItemClick, setSelectedItemClick] = useState(null);

    const handleClick = (index, content) => {
        setSelectedItemClick(index);
        onSubContent(content);
    };

    const resetClick = () => {
        setSelectedItemClick(null);
        onSubContent({});
    }

    useEffect(() => {
        resetClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <Chart className="SubPieChart">
            <SVG indexRes={indexRes} className="chart">
                <G indexRes={indexRes}>
                    {data.subContent?.map((item, index) => {
                        const startAngle = index * porcentaje * 3.6;
                        const endAngle = (index + 1) * porcentaje * 3.6;

                        const isSelected = index === selectedItem;
                        const isActive = index === selectedItemClick;

                        const pathDescription = describeArc(0, 0, radius, startAngle, endAngle);

                        const lineHeight = 16 / indexRes; // Altura de cada línea de texto
                        const lines = item.titulo.split("\\n"); // Separar el texto por saltos de línea
                        const lineCount = lines.length; // Contar la cantidad de líneas de texto
                        const textPosition = calculateTextPositionLevel(0, 0, radius, startAngle, endAngle, lineHeight, lineCount);

                        return (
                            <g key={index} className="chartButton">
                                <Path
                                    key={index}
                                    d={pathDescription}
                                    fill={isActive ? "#f9f9f9" : (!isSelected ? item.color : (data.coloranimacion ?? "#e9e9e9"))}
                                    stroke={isActive ? "#f9f9f9" : (!isSelected ? (data.colorborde ?? "#000000") : (data.coloranimacion ?? "#e9e9e9"))}
                                    strokeWidth={isActive ? "5px" : (!isSelected ? "1.5px" : "5px")}
                                    style={{ cursor: "pointer" }}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={() => handleClick(index, item)}
                                    colorAnimacion={(data.coloranimacion ?? "#e9e9e9")}
                                />
                                {lines.map((line, lineIndex) => (
                                    <Text
                                        key={`${index}-${lineIndex}`}
                                        textSize={item.tamanotexto}
                                        indexRes={indexRes}
                                        x={textPosition.x}
                                        y={textPosition.y + lineHeight * lineIndex} /* Ajuste de posición vertical */
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={() => handleClick(index, item)}
                                        fill={isActive ? "#000000" :(isSelected ? "#000000" : item.colortexto)}
                                    >
                                        {line}
                                    </Text>
                                ))}
                            </g>
                        );
                    })}
                    <circle r={110 / indexRes} fill="white" stroke={data.colorborde ?? '#000000'} strokeWidth={"1.5"} onClick={() => resetClick()} />
                    <text textAnchor="middle" dominantBaseline="middle">
                    </text>
                </G>
            </SVG>
        </Chart>
    );
};

// Función auxiliar para describir un arco en un camino SVG
function describeArc(x, y, radius, startAngle, endAngle) {
    const startRad = (startAngle * Math.PI) / 180;
    const endRad = (endAngle * Math.PI) / 180;

    const startX = x + radius * Math.cos(startRad);
    const startY = y + radius * Math.sin(startRad);
    const endX = x + radius * Math.cos(endRad);
    const endY = y + radius * Math.sin(endRad);

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    return `
        M ${startX},${startY}
        A ${radius},${radius} 0 ${largeArcFlag} 1 ${endX},${endY}
        L ${x},${y}
        Z
    `;
}

// Función auxiliar para calcular las coordenadas (x, y) del centro del arco
function calculateTextPositionLevel(x, y, radius, startAngle, endAngle) {
    const angle = (startAngle + endAngle) / 2;
    var textX = x + radius * Math.cos((angle * Math.PI) / 180);
    var textY = y + radius * Math.sin((angle * Math.PI) / 180);
    textX = textX / 1.35;
    textY = textY / 1.35;
    return { x: textX, y: textY };
}

// ? Definicion de estilos dinamicos
const Chart = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Path = styled.path`
    text-shadow: 5px;
    cursor: pointer;
    &:hover {
        fill: ${(props) => props.colorAnimacion};
        stroke: ${(props) => props.colorAnimacion};
        stroke-width: 5px;
    }
`;

const SVG = styled.svg`
    width: ${(props) => (500 / props.indexRes)}px;
    height: ${(props) => (500 / props.indexRes)}px;
`;

const G = styled.g`
    transform:  translate(${(props) => (250 / props.indexRes)}px, ${(props) => (250 / props.indexRes)}px);
`;

const Text = styled.text`
    font-size: ${(props) => (props.textSize ? props.textSize / props.indexRes : 14 / props.indexRes)}px;
    dominant-baseline: middle;
    text-anchor: middle;
    cursor: pointer;
`;

// * Función flecha para la obtención del tamaño del dispositivo
const useWindowsSize = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const [index, setIndex] = useState(1);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowSize <= 530 && windowSize > 280) {
            // Calcular proporcion
            const proportion = (windowSize - 280) / (250);
            // Calcular el index
            const result = 2 - (proportion * (1));
            // Setear index
            setIndex(parseFloat(result.toFixed(2)));

        } else if (windowSize <= 280) {
            setIndex(2);
        } else {
            setIndex(1);
        }
    }, [windowSize])

    return { windowSize, index };
}

export default SubPieChart;