import React, { useContext } from 'react';
import error500 from '../../Assets/errors/8459338.jpg';
import { Footer } from '../App/Footer';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../Context/LanguageContext';

export const Page500 = () => {

	const { texto } = useContext(LanguageContext);

	return (
		<>
			<section className='container-fluid min-vh-100 error-container'>
				<div className='row g-3'>
					<figure className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 align-self-center p-2'>
						<img src={error500} className='figure-img img-fluid img-cover' alt="Error 404" />
					</figure>
					<div className="container col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 align-self-center p-4">
						<h2 className="title-section font-error">500</h2>
						<hr className="title-divider bg-primary" />
						<p className="text-section font-18 lh-base"><b className='font-30'>{texto.error.error_500.parrafo1}</b> <br />{texto.error.error_500.parrafo2}</p>
						<div className="p-1">
							<Link to='/' className="btn btn-primary">{texto.acciones.inicio}</Link>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	)
}