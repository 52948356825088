import React, { useContext } from 'react';
import autorizacionSEPS from '../../Data/SEPS-ISF-DNAISF-2018-0104.pdf';
import autorization_img from '../../Assets/img/pexels-photo-5668473.jpeg';
import { LanguageContext } from '../../Context/LanguageContext';
export const AuthorizationSection = () => {

	const { texto } = useContext(LanguageContext);

	const openPDF = () => {
		const pdfUrl = autorizacionSEPS;
		window.open(pdfUrl, '_blank');
	}

	return (<>
		<section className="container-fluid text-center ">
			<div className="row g-1">
				<div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-7 align-self-center p-4">
					<h2 className="title-section">{texto.seccion.estructuras.titulo}</h2>
					<hr className="title-divider bg-primary m-auto mb-30" />
					<p className="text-section font-16">{texto.seccion.estructuras.parrafo1}</p>
					<div className="gap-2 p-1">
						<button onClick={openPDF} className="btn btn-primary">{texto.acciones.autorizacionSEPS}</button>
					</div>
					<br />
				</div>
				<figure className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 p-3'>
					<img src={autorization_img} className='figure-img img-fluid img-cover rounded shadow-3 h-100' alt="Nosotros" loading="lazy" />
				</figure>
			</div>
		</section>
	</>)
}
