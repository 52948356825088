import React, { useContext, useState } from 'react';
import { PAISES_AMERICANOS } from '../../Data/Constantes';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import axios from 'axios';
import { Toaster, toast } from 'sonner';
import { Footer } from '../App/Footer';
import { LanguageContext } from '../../Context/LanguageContext';

export const DeployRequestDemo = () => {

    const { language, texto } = useContext(LanguageContext);

    const apiUrl = process.env.REACT_APP_BACKEND_URL;

    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [nombre, setNombre] = useState('');
    const [institucion, setInstitucion] = useState('');
    const [id, setId] = useState('');
    const [correo, setCorreo] = useState('');
    const [codigoarea, setCodigoarea] = useState();
    const [telefono, setTelefono] = useState('');

    const telInputProps = {
        type:'number',
        placeholder: 'Ingresa tu número de teléfono',
        name: 'phoneNumber',
        required: true,
        pattern: '[0-9]*',
        minLength: '5',
        maxLength: '12'
    };

    const clearValues = () => {
        setNombre('');
        setInstitucion('');
        setId('');
        setCorreo('');
        setTelefono('');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const res = await axios.post(
                `${apiUrl}/${language.toLowerCase()}/deploy-request`,
                { 
                    "nombre": nombre, 
                    "institucion": institucion, 
                    "identificacion": id, 
                    "codigoarea": codigoarea,
                    "telefono": telefono,
                    "correo": correo 
                },
                { headers: { 'accept': 'application/json' } }
            )
            res.data.message && setMessage(res.data.message);
            toast.success(res.data.message);
            clearValues();
        } catch (err) {
            setError('Un error a ocurrido');
            console.error(err);
            if (err.response.status === 422) {
                toast.error(err.response.data.message);
            } else {
                toast.error('Un error ha ocurrido, inténtalo mas tarde.');
            }
        } finally {
            setLoading(false);
            console.log(message + "\n" + loading + "\n" + error);
        }
    }

    return (<>
        <Toaster richColors position="bottom-center" expand={true} />
        <br />
        <section id='contactus' className='container-fluid min-vh-100 d-flex'>
            <div className='row align-self-center'>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-4 bg-second mb-2'>
                    <h1 className="title-section font-50">{texto.seccion.deploy_reques.titulo}</h1>
                    <hr className="title-divider bg-primary" />
                    <p className="text-section font-18">{texto.seccion.deploy_reques.parrafo1}</p>
                    <br />
                    <h3 className='title-section font-30'>{texto.seccion.deploy_reques.faq.titulo}</h3>
                    <br />
                    <div class="accordion accordion-flush" id="accordionFAQ">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading-1">
                                <button
                                    data-mdb-collapse-init
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-mdb-toggle="collapse"
                                    data-mdb-target="#collapse-1"
                                    aria-expanded="false"
                                    aria-controls="collapse-1"
                                >
                                    {texto.seccion.deploy_reques.faq.q1}
                                </button>
                            </h2>
                            <div id="collapse-1" class="accordion-collapse collapse" aria-labelledby="heading-1" data-mdb-parent="#accordionFAQ">
                                <div class="accordion-body" dangerouslySetInnerHTML={{ __html: texto.seccion.deploy_reques.faq.r1 }}></div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading-2">
                                <button
                                    data-mdb-collapse-init
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-mdb-toggle="collapse"
                                    data-mdb-target="#collapse-2"
                                    aria-expanded="false"
                                    aria-controls="collapse-2"
                                >
                                    {texto.seccion.deploy_reques.faq.q2}
                                </button>
                            </h2>
                            <div id="collapse-2" class="accordion-collapse collapse" aria-labelledby="heading-2" data-mdb-parent="#accordionFAQ">
                                <div class="accordion-body" dangerouslySetInnerHTML={{ __html: texto.seccion.deploy_reques.faq.r2 }}></div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading-3">
                                <button
                                    data-mdb-collapse-init
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-mdb-toggle="collapse"
                                    data-mdb-target="#collapse-3"
                                    aria-expanded="false"
                                    aria-controls="collapse-3"
                                >
                                    {texto.seccion.deploy_reques.faq.q3}
                                </button>
                            </h2>
                            <div id="collapse-3" class="accordion-collapse collapse" aria-labelledby="heading-3" data-mdb-parent="#accordionFAQ">
                                <div class="accordion-body" dangerouslySetInnerHTML={{ __html: texto.seccion.deploy_reques.faq.r3 }}></div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading-4">
                                <button
                                    data-mdb-collapse-init
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-mdb-toggle="collapse"
                                    data-mdb-target="#collapse-4"
                                    aria-expanded="false"
                                    aria-controls="collapse-4"
                                >
                                    {texto.seccion.deploy_reques.faq.q4}
                                </button>
                            </h2>
                            <div id="collapse-4" class="accordion-collapse collapse" aria-labelledby="heading-4" data-mdb-parent="#accordionFAQ">
                                <div class="accordion-body" dangerouslySetInnerHTML={{ __html: texto.seccion.deploy_reques.faq.r4 }}></div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading-5">
                                <button
                                    data-mdb-collapse-init
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-mdb-toggle="collapse"
                                    data-mdb-target="#collapse-5"
                                    aria-expanded="false"
                                    aria-controls="collapse-5"
                                >
                                    {texto.seccion.deploy_reques.faq.q5}
                                </button>
                            </h2>
                            <div id="collapse-5" class="accordion-collapse collapse" aria-labelledby="heading-5" data-mdb-parent="#accordionFAQ">
                                <div class="accordion-body">
                                    <p dangerouslySetInnerHTML={{ __html: texto.seccion.deploy_reques.faq.r5 }}></p>
                                    <a className="btn btn-sm text-white bg-success btn-rounded" href="https://wa.me/593984266250" target='blank' role="button">
                                        <i className="bi bi-whatsapp font-14 me-2"></i>
                                        Whatsapp
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 align-self-center px-5 '>
                <h2 className="title-section text-center font-40">{texto.seccion.deploy_reques.subtitulo}</h2>
					<hr className="title-divider bg-primary m-auto" />
					<p className="text-section text-center font-18">{texto.seccion.deploy_reques.parrafo2}</p>
                    <form className='font-16' onSubmit={handleSubmit} >
                        <div className="form-outline mb-3">
                            <input
                                type="text"
                                id="nombre"
                                autoComplete="off"
                                name='nombre'
                                minLength="3"
                                maxLength="50"
                                value={nombre}
                                className="form-control bg-white color-white"
                                onChange={(e) => setNombre(e.target.value)}
                                required />
                            <label className="form-label font-16" htmlFor="nombre">{texto.inicio.contactenos.form.nombre}</label>
                            <div className="form-notch">
                                <div className="form-notch-leading" ></div>
                                <div className="form-notch-middle" ></div>
                                <div className="form-notch-trailing"></div>
                            </div>
                        </div>
                        <div className="form-outline mb-3">
                            <input
                                type="text"
                                id="institucion"
                                autoComplete="off"
                                name='institucion'
                                minLength="3"
                                maxLength="50"
                                value={institucion}
                                className="form-control"
                                onChange={(e) => setInstitucion(e.target.value)}
                                required />
                            <label className="form-label font-16" htmlFor="institucion">{texto.inicio.contactenos.form.pertenece}</label>
                            <div className="form-notch">
                                <div className="form-notch-leading" ></div>
                                <div className="form-notch-middle" ></div>
                                <div className="form-notch-trailing"></div>
                            </div>
                        </div>
                        <div className="form-outline mb-3">
                            <input
                                type="number"
                                id="id"
                                autoComplete="off"
                                name='id'
                                minLength="3"
                                maxLength="19"
                                pattern='[0-9]*'
                                value={id}
                                className="form-control"
                                onChange={(e) => setId(e.target.value)}
                                required />
                            <label className="form-label font-16" htmlFor="id">{texto.inicio.contactenos.form.identificacion}</label>
                            <div className="form-notch">
                                <div className="form-notch-leading" ></div>
                                <div className="form-notch-middle" ></div>
                                <div className="form-notch-trailing"></div>
                            </div>
                        </div>
                        <div className="form-outline mb-3">
                            <input
                                type="email"
                                id="correo"
                                autoComplete="on"
                                name='correo'
                                minLength="3"
                                maxLength="50"
                                value={correo}
                                className="form-control"
                                onChange={(e) => setCorreo(e.target.value)}
                                required />
                            <label className="form-label font-16" htmlFor="correo">{texto.inicio.contactenos.form.correo}</label>
                            <div className="form-notch">
                                <div className="form-notch-leading" ></div>
                                <div className="form-notch-middle" ></div>
                                <div className="form-notch-trailing"></div>
                            </div>
                        </div>
                        <div className="d-grid mb-3">
                            <IntlTelInput
                                inputClassName='form-control'
                                value={telefono}
                                onPhoneNumberChange={(status, phoneNumber, countryData, formattedPhoneNumber, isValid) => setTelefono(phoneNumber)}
                                onPhoneNumberBlur={(status, phoneNumber, countryData, formattedPhoneNumber, isValid) => setCodigoarea(countryData.dialCode)}
                                preferredCountries={['EC']}
                                onlyCountries={PAISES_AMERICANOS}
                                telInputProps={telInputProps} />
                        </div>
                        {loading === true ?
                            <button type="submit" className="btn btn-primary btn-block mb-3 disabled font-16">{texto.acciones.solicitud_anviada}</button> :
                            <button type="submit" className="btn btn-primary btn-block mb-3 font-16">{texto.acciones.solicitar_demo}</button>}
                    </form>
                </div>
            </div>
        </section>
        <br />
		<Footer />
    </>)
}