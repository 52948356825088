import React, { createContext, useState, useEffect } from "react";
import {IDIOMA_TEXTO} from '../Data/Idioma';

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
	const [language, setLanguage] = useState(
		localStorage.getItem("language") || "ES"
	);

	let texto = IDIOMA_TEXTO[language];

	useEffect(() => {
		texto = IDIOMA_TEXTO[language];
		localStorage.setItem("language", language);
	}, [language]);

	return (
		<LanguageContext.Provider value={{ language, setLanguage, texto }}>
			{children}
		</LanguageContext.Provider>
	);
};

export { LanguageProvider, LanguageContext };
